import { BrainSearchMessage } from "@/brain-search/internal/BrainSearchPage"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

interface BrainSearchUserMessageProps extends TestIDProps {
  message: BrainSearchMessage
}

function BrainSearchUserMessage({ message }: BrainSearchUserMessageProps) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div
      key={message.id}
      className={classNames(classes.message, classes.userMessage, classes.fadeIn)}
    >
      <DiscoEditor
        key={message.messageText}
        readOnly
        disableGutter
        markdown={message.messageText}
        fontSize={"14px"}
        backgroundColor={"transparent"}
        textColor={theme.palette.common.white}
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  message: {
    width: "100%",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  userMessage: {
    padding: theme.spacing(1, 2),
    borderRadius: theme.measure.borderRadius.big,
    borderBottomRightRadius: 0,
    backgroundColor: theme.palette.primary.main,
  },
  fadeIn: {
    animation: "$fadeIn 1s",
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}))

export default BrainSearchUserMessage

import { useStartDirectMessageMutation } from "@/chat/hooks/__generated__/useStartDirectMessageMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useAddStreamChannelToContext } from "@/core/context/StreamChatContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { useQueryParams } from "@utils/url/urlUtils"
import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"
/**
 * Returns a callback that starts a direct message with the provided organizationMembershipIds.
 * @returns [callback, isSubmitting]
 */
export default function useStartDirectMessage(): [
  (orgMemberIds: GlobalID[]) => Promise<boolean>,
  boolean
] {
  const addStreamChannelToContext = useAddStreamChannelToContext()

  const runDirectMessageMutation = Relay.useAsyncMutation<useStartDirectMessageMutation>(
    graphql`
      mutation useStartDirectMessageMutation($input: StartDirectMessageInput!) {
        response: startDirectMessage(input: $input) {
          node {
            id
            externalChannelId
            organization {
              ...StreamChatContext_ChannelsFragment
            }
          }
          errors {
            field
            message
          }
        }
      }
    `
  )

  const [isSubmitting, setIsSubmitting] = useState(false)

  const history = useHistory()
  const activeOrganization = useActiveOrganization()
  const isMobile = useIsMobile()

  const memberLabel = useLabel("organization_member")

  const startDirectMessage = useCallback(
    async (orgMemberIds: GlobalID[]): Promise<boolean> => {
      if (!activeOrganization?.viewerMembership?.id) {
        displayErrorToast({
          message: `You must be a ${memberLabel.singular} of the community to start a Private Message.`,
        })
        return false
      }

      setIsSubmitting(true)

      try {
        const { response } = await runDirectMessageMutation({
          input: {
            organizationMembershipIds: [
              activeOrganization.viewerMembership.id,
              ...orgMemberIds,
            ],
          },
        })
        setIsSubmitting(false)
        // Display validation errors.
        if (response.errors && response.errors[0]) {
          displayErrorToast(response.errors[0])
          return false
        }
        if (!response.node) {
          return false
        }

        await addStreamChannelToContext(response.node.externalChannelId, true)
        history.push(
          generatePath(ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL, {
            channelId: response.node.id,
          })
        )
        return true
      } catch (err) {
        setIsSubmitting(false)
        displayErrorToast(err)
        return false
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      runDirectMessageMutation,
      setIsSubmitting,
      activeOrganization?.viewerMembership?.id,
      history,
      isMobile,
    ]
  )

  return [startDirectMessage, isSubmitting]
}

/**
 * listen for openDM query param and, if present,
 * open conversation with organzation members provided as comma-separated list
 */
export function useStartDMFromQueryParams() {
  const { openDM } = useQueryParams<{ openDM?: GlobalID }>()
  const [startDirectMessage] = useStartDirectMessage()

  useEffect(() => {
    const orgMemberIds = openDM ? openDM.split(",") : []
    if (orgMemberIds.length >= 1) startDirectMessage(orgMemberIds)
  }, [openDM, startDirectMessage])
}

import ChannelsSidebarProduct from "@/chat/sidebar/channels/ChannelsSidebarProduct"
import { ChannelsNavSectionFragment$key } from "@/chat/sidebar/channels/__generated__/ChannelsNavSectionFragment.graphql"
import CommunitySidebarItem from "@/organization/common/sidebar/CommunitySidebarItem"
import ChatChannelSideBarItem from "@/product/sidebar/chat/channels/ChatChannelSideBarItem"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

type Props = TestIDProps & {
  navSectionKey: ChannelsNavSectionFragment$key
}

export default function ChannelsNavSection(props: Props) {
  const { navSectionKey, testid = "ChannelsNavSection" } = props
  const theme = useTheme()

  const navSection = useFragment<ChannelsNavSectionFragment$key>(
    graphql`
      fragment ChannelsNavSectionFragment on NavSection {
        id
        kind
        title
        apps(kinds: [chat_channel]) {
          edges {
            node {
              id
              ...ChatChannelSideBarItemFragment
              chatChannel {
                externalChannelId
              }
            }
          }
        }
        products(viewerIsMember: true) {
          edges {
            node {
              id
              ...ChannelsSidebarProductFragment
              productApps(kinds: [chat_channel, nav_folder]) {
                edges {
                  node {
                    id
                    chatChannel {
                      externalChannelId
                    }
                    productApps(kinds: [chat_channel]) {
                      edges {
                        node {
                          id
                          chatChannel {
                            externalChannelId
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    navSectionKey
  )
  const isProducts = navSection.kind === "products"
  const apps = Relay.connectionToArray(navSection.apps)
  const products = Relay.connectionToArray(navSection.products).filter((p) =>
    p.productApps.edges.some(
      (a) => a.node.chatChannel || a.node.productApps?.edges.length
    )
  )

  const classes = useStyles()

  // Don't show sections without any channels or products with channels
  if (!apps.length && !products.length) return null

  return (
    <CommunitySidebarItem
      testid={testid}
      menuItemClasses={{ root: classes.menuItem }}
      name={navSection.title}
      collapsible
      ignoreLocalStorage
      nameNode={
        <DiscoText
          variant={"body-sm-500-uppercase"}
          color={
            theme.palette.type === "dark" ? "groovy.onDark.300" : "groovy.neutral.400"
          }
          truncateText={1}
        >
          {navSection.title}
        </DiscoText>
      }
      notificationConfig={getNotificationConfig()}
    >
      <div data-testid={`${testid}.children`}>
        {isProducts
          ? products.map((product, i) => (
              <ChannelsSidebarProduct
                key={product.id}
                testid={`${testid}.products.${i}`}
                productKey={product}
              />
            ))
          : apps.map((app) => (
              <ChatChannelSideBarItem
                key={app.id}
                testid={testid}
                appKey={app}
                isChannelsSidebar
              />
            ))}
      </div>
    </CommunitySidebarItem>
  )

  function getNotificationConfig() {
    const channelApps = isProducts
      ? products.flatMap((p) =>
          Relay.connectionToArray(p.productApps).flatMap((a) =>
            a.chatChannel ? a : Relay.connectionToArray(a.productApps)
          )
        )
      : apps
    return { streamChannelIds: channelApps.map((a) => a.chatChannel!.externalChannelId) }
  }
}

const useStyles = makeUseStyles({
  menuItem: {
    "&:hover": {
      backgroundColor: "unset",
    },
  },
})

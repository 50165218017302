/**
 * @generated SignedSource<<6e9a485fc9d55babd653f20d8f5c5ef0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductAppKind = "content" | "link" | "curriculum" | "events" | "members" | "resources" | "slack" | "collection" | "product_link" | "organization_events" | "organization_members" | "chat_channel" | "posts" | "nav_folder" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ChannelsSidebarProductFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly status: ProductStatus;
  readonly badge: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  } | null;
  readonly productApps: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly kind: ProductAppKind;
        readonly customAppTitle: string | null;
        readonly productApps: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly chatChannel: {
                readonly externalChannelId: string;
              } | null;
              readonly " $fragmentSpreads": FragmentRefs<"ChatChannelSideBarItemFragment">;
            };
          }>;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"ChatChannelSideBarItemFragment" | "AppSidebarItemFragment">;
      };
    }>;
  };
  readonly " $fragmentType": "ChannelsSidebarProductFragment";
};
export type ChannelsSidebarProductFragment$key = {
  readonly " $data"?: ChannelsSidebarProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChannelsSidebarProductFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ChatChannelSideBarItemFragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChannelsSidebarProductFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BadgeFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "kinds",
          "value": [
            "chat_channel",
            "nav_folder"
          ]
        }
      ],
      "concreteType": "ProductAppNodeConnection",
      "kind": "LinkedField",
      "name": "productApps",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAppNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductApp",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customAppTitle",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "kinds",
                      "value": [
                        "chat_channel"
                      ]
                    }
                  ],
                  "concreteType": "ProductAppNodeConnection",
                  "kind": "LinkedField",
                  "name": "productApps",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductAppNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProductApp",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ChatChannel",
                              "kind": "LinkedField",
                              "name": "chatChannel",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "externalChannelId",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "productApps(kinds:[\"chat_channel\"])"
                },
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AppSidebarItemFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "productApps(kinds:[\"chat_channel\",\"nav_folder\"])"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "a29496a7da362653712ff6d1b59c9347";

export default node;

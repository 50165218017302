import { BrainSearchMessage } from "@/brain-search/internal/BrainSearchPage"
import { NodeFromConnection } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { TestIDProps } from "@utils/typeUtils"

import classnames from "classnames"

interface Props extends TestIDProps {
  suggestedFollowup: NodeFromConnection<BrainSearchMessage["suggestedFollowups"]>
  onClickSuggestion: (text: string) => Promise<void>
}

function BrainSearchMessageSuggestedFollowup({
  suggestedFollowup,
  onClickSuggestion,
}: Props) {
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()

  if (!suggestedFollowup.text) return null

  return (
    <DiscoContainerButton
      key={suggestedFollowup.text}
      className={classnames(classes.suggestion, showOnHoverClasses.hoverable)}
      onClick={handleClickSuggestion}
    >
      <DiscoText variant={"body-sm"}>{suggestedFollowup.text}</DiscoText>

      <DiscoIcon
        icon={"arrow-stem-right"}
        className={classnames(classes.arrowIcon, showOnHoverClasses.showable)}
      />
    </DiscoContainerButton>
  )

  function handleClickSuggestion() {
    onClickSuggestion(suggestedFollowup.text)
  }
}

const useStyles = makeUseStyles((theme) => ({
  suggestion: {
    width: "100%",
    textAlign: "left",
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(1, 2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& > p": {
      color: theme.palette.groovy.grey[500],
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      "& > p": {
        color: theme.palette.text.primary,
      },
    },
  },
  arrowIcon: {
    color: theme.palette.text.primary,
    fontSize: "1rem",
  },
}))

export default BrainSearchMessageSuggestedFollowup

import useDismissNotifications from "@/apps/util/hooks/useDismissNotifications"
import { useCommentsSectionContext } from "@/comments/CommentsSectionContext"
import CommentTimestamp from "@/comments/list/item/comment-timestamp/CommentTimestamp"
import CommentsListReplyItem from "@/comments/list/item/reply-item/CommentsListReplyItem"
import { CommentsListItem_comment$key } from "@/comments/list/item/__generated__/CommentsListItem_comment.graphql"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import ReplyIcon from "@/core/ui/iconsax/linear/custom-reply.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ContentReactionButton from "@/product/common/content/button/ContentReactionButton"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import ContentUniqueReactionsList from "@components/reactions/ContentUniqueReactionsList"
import { DiscoIconButton, DiscoText, DiscoTextButton } from "@disco-ui"
import { Grid, useTheme } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { useQueryParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import pluralize from "pluralize"
import React, { useEffect, useRef, useState } from "react"
import { graphql, useFragment } from "react-relay"
import CommentMoreActionsDropdown from "./more-actions-dropdown/CommentMoreActionsDropdown"

interface CommentsListItemProps {
  commentKey: CommentsListItem_comment$key
  testid: string
  classes?: { editor?: string }
  collapsed?: boolean
}

type CommentsListItemParam = {
  // The anchor comment ID from the query param
  comment?: GlobalID
}

function CommentsListItem({
  commentKey,
  testid,
  collapsed = false,
}: CommentsListItemProps) {
  const { replyTo, setCommentReply, submittingCommentId, setSubmittingCommentId } =
    useCommentsSectionContext()
  const commentRef = useRef<HTMLDivElement>(null)
  const commentContainerRef = useRef<HTMLDivElement>(null)
  const { comment: anchorCommentId } = useQueryParams<CommentsListItemParam>()
  const isMobile = useIsMobile()
  const isWebView = useIsWebView()
  const activeProduct = useActiveProduct()
  const isNonMember = Boolean(activeProduct && !activeProduct.viewerMembership)
  const comment = useFragment<CommentsListItem_comment$key>(
    graphql`
      fragment CommentsListItem_comment on Comment {
        id
        creationDatetime
        contentId
        contentUsageId
        parentCommentId
        productId
        ...CommentMoreActionsDropdownFragment
        user {
          id
          fullName
          ...ProfileAvatarWithDetailsFragment
        }
        content {
          richEditorDescriptionContent
          ...DiscoEditorMentionsFragment
          ...ContentReactionButton_ContentFragment
        }
        replies {
          edges {
            node {
              id
              ...CommentsListReplyItem_comment
            }
          }
        }
      }
    `,
    commentKey
  )

  const isCurrentComment = comment.id === anchorCommentId
  const isReplying = comment.user === replyTo

  useEffect(() => {
    // Scroll to the current comment and focus it
    if (isCurrentComment && commentRef.current && commentContainerRef.current) {
      commentContainerRef.current.focus()
      window.scrollTo({ top: commentRef.current.offsetTop, behavior: "smooth" })
    }
  }, [isCurrentComment, commentRef])

  // Dimiss any notification related to this comment
  useDismissNotifications({
    entityId: comment.id,
    kind: "content-comment-published",
  })

  const replies = Relay.connectionToArray(comment.replies)
  const hasReplies = replies.length > 0

  const [showReplies, setShowReplies] = useState(
    isMobile || isWebView || replies.some((r) => r.id === anchorCommentId)
      ? !collapsed
      : collapsed
  )

  useEffect(() => {
    // if replies are hidden and comment is submitted, then show replies
    if (!showReplies && submittingCommentId === comment.id) {
      setShowReplies(true)
      setSubmittingCommentId(null)
    }
  }, [showReplies, submittingCommentId, comment.id, setSubmittingCommentId])

  const labels = useLabels()
  const theme = useTheme()
  const classes = useStyles({ hasReplies, isReplying, showReplies })
  const showOnHoverClasses = useShowOnHoverStyles()

  if (!comment) return null

  return (
    <>
      <div
        ref={commentRef}
        className={classNames(classes.commentContainer, showOnHoverClasses.hoverable)}
        id={comment.id}
        // Make this div focusable
        /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
        role={"button"}
        tabIndex={-1}
      >
        {/* More Actions Dropdown */}
        <CommentMoreActionsDropdown
          moreActionsButtonClassName={classNames(
            classes.commentMoreActionsButton,
            showOnHoverClasses.showable
          )}
          commentKey={comment}
        />

        {/* Commentor Avatar and Name */}
        <div>
          <ProfileAvatarWithDetails
            avatarSize={32}
            userKey={comment.user}
            testid={`${testid}.name-and-avatar`}
            horizontal
            highlight={isReplying ? "details" : "none"}
            details={<CommentTimestamp creationDatetime={comment.creationDatetime} />}
          />
        </div>

        <Grid
          ref={commentContainerRef}
          container
          className={classes.commentsContainer}
          /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
          role={"button"}
          tabIndex={-1}
        >
          <div className={classes.commentsContentContainer}>
            {/* Comment Text */}
            <div className={classes.commentTextContainer}>{renderCommentText()}</div>

            {/* Comment Reactions */}
            {comment.content && (
              <div className={classes.reactionsRow}>
                <ContentReactionButton
                  testid={"Comment"}
                  contentId={comment.contentId}
                  contentKey={comment.content}
                  hideNumber
                  hideText
                  size={"xsmall"}
                />
                <ContentUniqueReactionsList contentId={comment.contentId} />
              </div>
            )}
            <Grid container alignItems={"center"}>
              {/* When collapsed, show "View Replies" to expand replies */}
              {
                // Don't show "Reply" button for non-members
                !isNonMember && comment.user && (
                  <DiscoTextButton
                    onClick={() => composeReply(comment.user!)}
                    testid={`${testid}.reply-button`}
                    className={classes.replyButton}
                    textVariant={"body-sm-600"}
                  >
                    {"Reply"}
                  </DiscoTextButton>
                )
              }
              {!showReplies && hasReplies && (
                <div className={classes.showRepliesButton}>
                  <DiscoIconButton height={"16px"} width={"16px"}>
                    <ReplyIcon />
                  </DiscoIconButton>

                  <DiscoTextButton
                    onClick={() => setShowReplies(true)}
                    testid={`${testid}.show-replies`}
                    textVariant={"body-sm-600"}
                    color={theme.palette.groovy.onDark[300]}
                    style={{ marginLeft: replies.length > 1 ? 0 : theme.spacing(-0.5) }}
                  >
                    {`${replies.length} ${pluralize("Reply", replies.length)}`}
                  </DiscoTextButton>
                </div>
              )}
            </Grid>
          </div>
        </Grid>
      </div>

      {/* Replies & Reply Editor */}
      {showReplies && (
        <>
          {replies.map((reply, index) => (
            <CommentsListReplyItem
              key={reply.id}
              commentKey={reply}
              onClickReply={composeReply}
              testid={`CommentsListReplyItem.item-${index}`}
            />
          ))}
        </>
      )}

      {showReplies && hasReplies && (
        <Grid className={classes.hideRepliesContainer}>
          <div>
            <DiscoTextButton
              onClick={() => setShowReplies(false)}
              testid={`${testid}.hide-replies`}
              color={theme.palette.groovy.onDark[300]}
              textVariant={"body-sm-600"}
              className={classes.hideRepliesButton}
            >
              {"Hide replies"}
            </DiscoTextButton>
          </div>
        </Grid>
      )}
    </>
  )

  function renderCommentText() {
    if (comment.content?.richEditorDescriptionContent) {
      return (
        <DiscoEditor
          defaultValue={comment.content.richEditorDescriptionContent}
          testid={`${testid}.CommentsListItem.rich-editor`}
          readOnly
          disableGutter
          textColor={theme.palette.text.secondary}
          backgroundColor={"transparent"}
          config={"comment"}
          mentionsContentKey={comment.content}
        />
      )
    }
    return (
      <DiscoText
        testid={`${testid}.CommentListItem.NoLongerPartOfSpace`}
        className={classes.userNoLongerPartOfSpaceText}
        variant={"body-md"}
        color={"text.secondary"}
      >{`${
        comment.productId
          ? labels.product_member.singular
          : labels.organization_member.singular
      } no longer part of this ${
        comment.productId ? labels.experience.singular : "Community"
      }`}</DiscoText>
    )
  }

  function composeReply(toUser: { id: GlobalID; fullName: string }) {
    setCommentReply(toUser, comment.id)
  }
}

type StyleProps = {
  hasReplies: boolean
  isReplying: boolean
  showReplies: boolean
}

const useStyles = makeUseStyles((theme) => ({
  commentContainer: {
    position: "relative",
    padding: `0 ${theme.spacing(1)}px`,
  },
  commentMoreActionsButton: {
    position: "absolute",
    top: `${theme.spacing(1)}px`,
    right: "0",
  },
  commentsContainer: ({ hasReplies, showReplies }: StyleProps) => ({
    marginLeft: theme.spacing(2.25),
    paddingLeft: theme.spacing(3.25),
    marginTop: theme.spacing(0.5),
    ...styleIf(hasReplies && showReplies, {
      borderLeft: `2px solid ${theme.palette.groovy.neutral[300]}`,
      marginLeft: theme.spacing(2),
    }),
  }),
  commentsContentContainer: {
    width: "100%",
    marginTop: theme.spacing(-1.25),
  },
  commentTextContainer: ({ isReplying, hasReplies }: StyleProps) => ({
    marginBottom: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.25),
    ...styleIf(isReplying, {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(-1),
      paddingLeft: theme.spacing(1),
      backgroundColor: theme.palette.groovy.blue[100],
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      ...styleIf(hasReplies, {
        marginRight: theme.spacing(0.75),
      }),
    }),
  }),
  showRepliesButton: {
    justifyContent: "left",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(-2.25),
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    "&:hover, &:active": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[200],
      color: theme.palette.text.primary,
      "& svg": {
        color: theme.palette.text.primary,
      },
      "& span": {
        color: theme.palette.text.primary,
      },
    },
  },
  hideRepliesContainer: {
    display: "grid",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2.5),
    marginLeft: theme.spacing(3),
    borderLeft: `2px solid ${theme.palette.groovy.neutral[300]}`,
  },
  hideRepliesButton: {
    justifyContent: "left",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(1),
    "&:hover, &:active": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[200],
      color: theme.palette.text.primary,
      "& span": {
        color: theme.palette.text.primary,
      },
    },
  },
  replyButton: {
    justifyContent: "left",
    paddingTop: theme.spacing(1),
  },
  userNoLongerPartOfSpaceText: {
    fontStyle: "italic !important",
  },
  reactionsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
}))

export const CommentsListItemSkeleton: React.FC = () => {
  const classes = useStyles({ hasReplies: false, isReplying: false, showReplies: false })
  const theme = useTheme()

  return (
    <div className={classes.commentContainer}>
      {/* Commentor Avatar and Name */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: `${theme.spacing(1)}px 0`,
        }}
      >
        <Skeleton variant={"circle"} height={24} width={24} />
        <Skeleton height={15} width={95} style={{ marginLeft: theme.spacing(1.5) }} />
      </div>

      <Grid container className={classes.commentsContainer}>
        {/* Comment Text */}
        <div className={classes.commentTextContainer}>
          <Skeleton height={24} width={200} />
        </div>

        <Grid container alignItems={"center"}>
          {/* Comment Timestamp */}
          <Skeleton height={13} width={90} />
        </Grid>
      </Grid>
    </div>
  )
}

export default CommentsListItem

import SidebarContactAdminsCarouselItem from "@/chat/sidebar/contact-admins/SidebarContactAdminsCarouselItem"
import { SidebarContactAdminsCarouselQuery } from "@/chat/sidebar/contact-admins/__generated__/SidebarContactAdminsCarouselQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import DiscoExpand from "@/expand/DiscoExpand"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoCarousel, DiscoCarouselSkeleton, DiscoText } from "@disco-ui"
import { DiscoCardSkeleton } from "@disco-ui/card/DiscoCard"
import { useTheme } from "@material-ui/core"
import { graphql, useLazyLoadQuery } from "react-relay"

function SidebarContactAdminsCarousel() {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()
  const theme = useTheme()

  const { organization } = useLazyLoadQuery<SidebarContactAdminsCarouselQuery>(
    graphql`
      query SidebarContactAdminsCarouselQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            organizationMemberships(
              roles: [owner, admin]
              excludeViewer: true
              orderBy: member_name
              direction: ASC
            ) {
              edges {
                node {
                  id
                  member {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `,
    { id: activeOrganization.id },
    { fetchPolicy: "network-only" }
  )

  if (!organization) return null

  const oms = Relay.connectionToArray(organization.organizationMemberships)
  if (!oms.length) return null

  return (
    <DiscoExpand
      testid={"SidebarContactAdminsCarousel"}
      header={
        <DiscoText
          variant={"body-sm-500-uppercase"}
          color={
            theme.palette.type === "dark" ? "groovy.neutral.600" : "groovy.neutral.400"
          }
        >
          {"Contact Admins"}
        </DiscoText>
      }
      variant={"caret"}
      isDefaultExpanded={false}
      mountOnExpand={false}
    >
      <DiscoCarousel
        hideIndicator
        className={classes.carousel}
        data={oms}
        item={(om, i) => (
          <SidebarContactAdminsCarouselItem
            key={om.id}
            userId={om.member.id}
            testid={`SidebarContactAdminsCarouselItem.${i}`}
          />
        )}
        totalCount={oms.length}
        slidesPerView={1.75}
      />
    </DiscoExpand>
  )
}

const useStyles = makeUseStyles((theme) => ({
  carousel: {
    margin: theme.spacing(-1, 0, -1.5),
    padding: 0,
  },
}))

function SidebarContactAdminsCarouselSkeleton() {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <DiscoExpand
      header={
        <DiscoText
          variant={"body-sm-500-uppercase"}
          color={
            theme.palette.type === "dark" ? "groovy.neutral.600" : "groovy.neutral.400"
          }
        >
          {"Contact Admins"}
        </DiscoText>
      }
      variant={"caret"}
      isDefaultExpanded={false}
    >
      <DiscoCarouselSkeleton
        className={classes.carousel}
        item={<DiscoCardSkeleton />}
        slidesPerView={2}
        showHeader={false}
      />
    </DiscoExpand>
  )
}

export default Relay.withSkeleton({
  component: SidebarContactAdminsCarousel,
  skeleton: SidebarContactAdminsCarouselSkeleton,
})

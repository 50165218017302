/**
 * @generated SignedSource<<d27a5d2891fedc842dcdb34827248557>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BotSuggestionsContextQuery$variables = {
  id: string;
};
export type BotSuggestionsContextQuery$data = {
  readonly node: {
    readonly botMessages?: {
      readonly " $fragmentSpreads": FragmentRefs<"BotSuggestionsContextFragment">;
    } | null;
  } | null;
};
export type BotSuggestionsContextQuery = {
  variables: BotSuggestionsContextQuery$variables;
  response: BotSuggestionsContextQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 25
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BotSuggestionsContextQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "BotQueuedMessageNodeConnection",
                "kind": "LinkedField",
                "name": "botMessages",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "BotSuggestionsContextFragment"
                  }
                ],
                "storageKey": "botMessages(first:25)"
              }
            ],
            "type": "ChatChannel",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BotSuggestionsContextQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "BotQueuedMessageNodeConnection",
                "kind": "LinkedField",
                "name": "botMessages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BotQueuedMessageNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BotQueuedMessage",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "messageChannel",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "messageThreadTs",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "messageTs",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "suggestedMessage",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "suggestedMentionedPlatformUserIds",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "botMessages(first:25)"
              }
            ],
            "type": "ChatChannel",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e21b47e6337edb1e3ffb908019ae966",
    "id": null,
    "metadata": {},
    "name": "BotSuggestionsContextQuery",
    "operationKind": "query",
    "text": "query BotSuggestionsContextQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on ChatChannel {\n      botMessages(first: 25) {\n        ...BotSuggestionsContextFragment\n      }\n    }\n    id\n  }\n}\n\nfragment BotSuggestionsContextFragment on BotQueuedMessageNodeConnection {\n  edges {\n    node {\n      id\n      messageChannel\n      messageThreadTs\n      messageTs\n      suggestedMessage\n      suggestedMentionedPlatformUserIds\n      ...ChatChannelBotSuggestedMessageFragment\n    }\n  }\n}\n\nfragment ChatChannelBotSuggestedMessageFragment on BotQueuedMessage {\n  id\n  suggestedMessage\n}\n"
  }
};
})();

(node as any).hash = "13436ac3453593b4e0da43244372b96b";

export default node;

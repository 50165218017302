/**
 * @generated SignedSource<<a2d40e4ce30ccded69ee984227eebb49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BookmarkButtonDeleteMutation$variables = {
  organizationId: string;
  contentId: string;
  contentUsageId?: string | null;
};
export type BookmarkButtonDeleteMutation$data = {
  readonly deleteBookmark: {
    readonly node: {
      readonly id: string;
      readonly content: {
        readonly id: string;
        readonly hasViewerBookmarked: boolean;
      };
      readonly contentUsage: {
        readonly id: string;
        readonly hasViewerBookmarked: boolean;
      } | null;
    } | null;
  };
};
export type BookmarkButtonDeleteMutation = {
  variables: BookmarkButtonDeleteMutation$variables;
  response: BookmarkButtonDeleteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentUsageId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "contentId",
    "variableName": "contentId"
  },
  {
    "kind": "Variable",
    "name": "contentUsageId",
    "variableName": "contentUsageId"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hasViewerBookmarked",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Content",
  "kind": "LinkedField",
  "name": "content",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ContentUsage",
  "kind": "LinkedField",
  "name": "contentUsage",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BookmarkButtonDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "DeleteBookmarkResponse",
        "kind": "LinkedField",
        "name": "deleteBookmark",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Bookmark",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "BookmarkButtonDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "DeleteBookmarkResponse",
        "kind": "LinkedField",
        "name": "deleteBookmark",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Bookmark",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "16bda1233cbbcc3f25b00dfc15c412fe",
    "id": null,
    "metadata": {},
    "name": "BookmarkButtonDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation BookmarkButtonDeleteMutation(\n  $organizationId: ID!\n  $contentId: ID!\n  $contentUsageId: ID\n) {\n  deleteBookmark(organizationId: $organizationId, contentId: $contentId, contentUsageId: $contentUsageId) {\n    node {\n      id\n      content {\n        id\n        hasViewerBookmarked\n      }\n      contentUsage {\n        id\n        hasViewerBookmarked\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8028b618f0cb8dcf6f62136321790232";

export default node;

import ThreadsSidebarListItem, {
  ThreadsSidebarListItemSkeleton,
} from "@/chat/sidebar/threads/ThreadsSidebarListItem"
import { ThreadsSidebarPaginationFragment$key } from "@/chat/sidebar/threads/__generated__/ThreadsSidebarPaginationFragment.graphql"
import { ThreadsSidebarPaginationQuery } from "@/chat/sidebar/threads/__generated__/ThreadsSidebarPaginationQuery.graphql"
import { ThreadsSidebarQuery } from "@/chat/sidebar/threads/__generated__/ThreadsSidebarQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoText } from "@disco-ui"
import { DiscoCardSkeleton } from "@disco-ui/card/DiscoCard"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { useTheme } from "@material-ui/core"
import { range } from "@utils/array/arrayUtils"
import { Fragment, useEffect } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { generatePath, Redirect, useHistory, useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"

const THREADS_PER_LOAD = 7

function ThreadsSidebar() {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()

  const { organization } = useLazyLoadQuery<ThreadsSidebarQuery>(
    graphql`
      query ThreadsSidebarQuery($id: ID!, $first: Int!, $after: String) {
        organization: node(id: $id) {
          ... on Organization {
            ...ThreadsSidebarPaginationFragment @arguments(first: $first, after: $after)
          }
        }
      }
    `,
    { id: activeOrganization.id, first: THREADS_PER_LOAD },
    { fetchPolicy: "network-only" }
  )

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    ThreadsSidebarPaginationQuery,
    ThreadsSidebarPaginationFragment$key
  >(
    graphql`
      fragment ThreadsSidebarPaginationFragment on Organization
      @refetchable(queryName: "ThreadsSidebarPaginationQuery")
      @argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
        viewerThreads(first: $first, after: $after)
          @connection(key: "ThreadsSidebar__viewerThreads") {
          totalCount
          edges {
            node {
              id
              ...ThreadsSidebarListItemFragment
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    organization
  )

  const threads = Relay.connectionToArray(data?.viewerThreads)

  // Redirect to the first thread in the list of none is currenly selected
  const location = useLocation()
  const history = useHistory()
  const theme = useTheme()
  const isDarkmode = theme.palette.type === "dark"
  useEffect(() => {
    if (location.pathname !== ROUTE_NAMES.COMMUNITY.THREADS.DETAIL_ROOT) return
    if (!threads.length) {
      history.replace(ROUTE_NAMES.COMMUNITY.THREADS.DETAIL_EMPTY)
      return
    }
    history.replace(
      generatePath(ROUTE_NAMES.COMMUNITY.THREADS.DETAIL, { threadId: threads[0].id })
    )
  }, [threads, location.pathname, history])

  if (!activeOrganization.isDmEnabled && !activeOrganization.isChannelsEnabled) {
    return <Redirect to={ROUTE_NAMES.COMMUNITY.HOME.ROOT} />
  }

  return (
    <div className={classes.list}>
      {threads.length ? (
        <>
          {threads.map((thread, i) => (
            <Fragment key={thread.id}>
              <DiscoDivider marginTop={i === 0 ? 0 : 1.5} marginBottom={1.5} />
              <ThreadsSidebarListItem
                threadKey={thread}
                testid={`ThreadsSidebarListItem.${i}`}
              />
            </Fragment>
          ))}
          {hasNext && (
            <DiscoScrolledIntoView
              onScrolledIntoView={() => loadNext(THREADS_PER_LOAD)}
              isLoading={isLoadingNext}
              skeleton={<DiscoCardSkeleton variant={"compact"} />}
            />
          )}
        </>
      ) : (
        <DiscoText
          variant={"body-sm"}
          color={isDarkmode ? "groovy.onDark.200" : "groovy.neutral.400"}
          marginTop={2}
          align={"center"}
        >
          {"No threads yet."}
        </DiscoText>
      )}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  list: {
    paddingBottom: theme.spacing(2),
  },
}))

function ThreadsSidebarSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.list}>
      {range(4).map((i) => (
        <Fragment key={i}>
          <DiscoDivider marginTop={i === 0 ? 0 : 1.5} marginBottom={1.5} />
          <ThreadsSidebarListItemSkeleton />
        </Fragment>
      ))}
    </div>
  )
}

export default Relay.withSkeleton({
  component: ThreadsSidebar,
  skeleton: ThreadsSidebarSkeleton,
})

/**
 * @generated SignedSource<<8298f463212101c6a7b35a1ac9e6fee0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BookmarkKind = "content_usage" | "occurrence" | "post" | "comment" | "%future added value";
export type CreateBookmarkInput = {
  organizationId: string;
  contentId: string;
  kind: BookmarkKind;
  contentUsageId?: string | null;
};
export type BookmarkButtonCreateMutation$variables = {
  input: CreateBookmarkInput;
};
export type BookmarkButtonCreateMutation$data = {
  readonly createBookmark: {
    readonly node: {
      readonly id: string;
      readonly content: {
        readonly id: string;
        readonly hasViewerBookmarked: boolean;
      };
      readonly contentUsage: {
        readonly id: string;
        readonly hasViewerBookmarked: boolean;
      } | null;
    } | null;
  };
};
export type BookmarkButtonCreateMutation = {
  variables: BookmarkButtonCreateMutation$variables;
  response: BookmarkButtonCreateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hasViewerBookmarked",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateBookmarkResponse",
    "kind": "LinkedField",
    "name": "createBookmark",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Bookmark",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Content",
            "kind": "LinkedField",
            "name": "content",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentUsage",
            "kind": "LinkedField",
            "name": "contentUsage",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookmarkButtonCreateMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookmarkButtonCreateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "38ffedd197e6804c953dd8630d0f6f25",
    "id": null,
    "metadata": {},
    "name": "BookmarkButtonCreateMutation",
    "operationKind": "mutation",
    "text": "mutation BookmarkButtonCreateMutation(\n  $input: CreateBookmarkInput!\n) {\n  createBookmark(input: $input) {\n    node {\n      id\n      content {\n        id\n        hasViewerBookmarked\n      }\n      contentUsage {\n        id\n        hasViewerBookmarked\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5bc125579683b8621fdb2cde6b893015";

export default node;

import BrainSearchMessageExpertResponseList from "@/brain-search/internal/BrainSearchMessageExpertResponseList"
import BrainSearchMessageSourceList from "@/brain-search/internal/BrainSearchMessageSourceList"
import BrainSearchMessageSuggestedFollowupList from "@/brain-search/internal/BrainSearchMessageSuggestedFollowupList"
import { BrainSearchMessage } from "@/brain-search/internal/BrainSearchPage"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

interface Props extends TestIDProps {
  message: BrainSearchMessage
  createMessage: (text: string) => Promise<void>
  isShared?: boolean
}

function BrainSearchAssistantMessage({ message, createMessage, isShared }: Props) {
  const classes = useStyles()
  const theme = useTheme()

  if (!message.messageText) return null

  return (
    <div key={message.id} className={classNames(classes.message, classes.fadeIn)}>
      {/* Sources */}
      <BrainSearchMessageSourceList message={message} />

      {/* Answer Tag */}
      <div className={classes.answerTag}>
        <DiscoIcon
          icon={"stars"}
          width={16}
          height={16}
          color={theme.palette.text.primary}
          active
        />
        <DiscoText variant={"heading-xs-700"}>{"Answer"}</DiscoText>
      </div>

      {/* Answer */}
      <DiscoEditor
        key={message.messageText}
        readOnly
        disableGutter
        markdown={message.messageText}
        fontSize={"14px"}
        disableScroll
      />

      {/* Expert Advice */}
      <BrainSearchMessageExpertResponseList
        key={JSON.stringify(message.expertResponses)}
        message={message}
      />

      {/* Suggested Followups */}
      {!isShared && (
        <BrainSearchMessageSuggestedFollowupList
          key={JSON.stringify(message.suggestedFollowups)}
          message={message}
          createMessage={createMessage}
        />
      )}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  message: {
    width: "100%",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  answerTag: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  fadeIn: {
    animation: "$fadeIn 0.5s",
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}))

export default BrainSearchAssistantMessage

import { ThreadPageContentSkeleton } from "@/chat/channel/page/content/ThreadPageContent"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import AuthRequiredRoute from "@/core/route/permissioned-route/AuthRequiredRoute"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import MetaDetails from "@/main/page/MetaDetails"
import useSubscriptionStanding from "@utils/hook/useSubscriptionStanding"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { Redirect, Switch } from "react-router-dom"

const ChatChannelPageContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "chat-channel-page-content" */ "@/chat/channel/page/content/ChatChannelPageContent"
    )
)
const CommunityNonActiveStandingPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "community-non-active-standing-page" */ "@/main/page/CommunityNonActiveStandingPage"
    )
)
const ChatChannelEmpty = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "chat-channel-empty" */ "@components/chat/channel/empty/ChatChannelEmpty"
    )
)

export default function ChatFlows() {
  const activeOrganization = useActiveOrganization()!
  const subscriptionStanding = useSubscriptionStanding()

  if (subscriptionStanding !== "active") {
    return <CommunityNonActiveStandingPage standing={subscriptionStanding} />
  }

  return (
    <MetaDetails
      metaDetailsForSharing={{
        title: activeOrganization.name,
        url: window.location.href,
        description: activeOrganization.description || "",
        coverPhoto: activeOrganization.cover || "",
      }}
      metaTitle={activeOrganization.name}
    >
      <Switch>
        <AuthRequiredRoute
          key={`routes:=${ROUTE_NAMES.CHAT.CHANNEL.ROOT}`}
          path={ROUTE_NAMES.CHAT.CHANNEL.ROOT}
          exact
        >
          <ThreadPageContentSkeleton />
        </AuthRequiredRoute>
        <AuthRequiredRoute
          key={`routes:=${ROUTE_NAMES.CHAT.CHANNEL.DETAIL_EMPTY}`}
          path={ROUTE_NAMES.CHAT.CHANNEL.DETAIL_EMPTY}
          exact
        >
          <ChatChannelEmpty state={"channels"} />
        </AuthRequiredRoute>
        <AuthRequiredRoute
          key={`routes:=${ROUTE_NAMES.CHAT.CHANNEL.DETAIL}`}
          path={ROUTE_NAMES.CHAT.CHANNEL.DETAIL}
        >
          <ChatChannelPageContent />
        </AuthRequiredRoute>

        <Redirect to={ROUTE_NAMES.COMMUNITY.HOME.ROOT} />
      </Switch>
    </MetaDetails>
  )
}

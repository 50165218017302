/**
 * @generated SignedSource<<1f2e73742a52353615e14653e6974841>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StartDirectMessageInput = {
  organizationMembershipIds: ReadonlyArray<string>;
};
export type useStartDirectMessageMutation$variables = {
  input: StartDirectMessageInput;
};
export type useStartDirectMessageMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly externalChannelId: string;
      readonly organization: {
        readonly " $fragmentSpreads": FragmentRefs<"StreamChatContext_ChannelsFragment">;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type useStartDirectMessageMutation = {
  variables: useStartDirectMessageMutation$variables;
  response: useStartDirectMessageMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalChannelId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useStartDirectMessageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "StartDirectMessageResponse",
        "kind": "LinkedField",
        "name": "startDirectMessage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatChannel",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "StreamChatContext_ChannelsFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useStartDirectMessageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "StartDirectMessageResponse",
        "kind": "LinkedField",
        "name": "startDirectMessage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatChannel",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "includeProductLevel",
                        "value": true
                      },
                      {
                        "kind": "Literal",
                        "name": "kinds",
                        "value": [
                          "default",
                          "custom",
                          "direct_message"
                        ]
                      }
                    ],
                    "concreteType": "ChatChannelNodeConnection",
                    "kind": "LinkedField",
                    "name": "chatChannels",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChatChannelNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ChatChannel",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "productId",
                                "storageKey": null
                              },
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "appId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductMembership",
                                    "kind": "LinkedField",
                                    "name": "viewerMembership",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "chatChannels(includeProductLevel:true,kinds:[\"default\",\"custom\",\"direct_message\"])"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a177743f7b1790479c096a5147819d9f",
    "id": null,
    "metadata": {},
    "name": "useStartDirectMessageMutation",
    "operationKind": "mutation",
    "text": "mutation useStartDirectMessageMutation(\n  $input: StartDirectMessageInput!\n) {\n  response: startDirectMessage(input: $input) {\n    node {\n      id\n      externalChannelId\n      organization {\n        ...StreamChatContext_ChannelsFragment\n        id\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment StreamChatContext_ChannelsFragment on Organization {\n  chatChannels(kinds: [default, custom, direct_message], includeProductLevel: true) {\n    edges {\n      node {\n        id\n        productId\n        externalChannelId\n        kind\n        appId\n        product {\n          viewerMembership {\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "73cfe5930bcd3762ba14560b4ee3652b";

export default node;

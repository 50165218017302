export type RoleData = {
  experts: Expert[]
  prompts: string[]
}

export type Expert = {
  [key: string]: {
    avatar: string
    bio: string
  }
}

export const ROLES: Record<string, RoleData> = {
  "CEO - Startup": {
    experts: [
      {
        "Reid Hoffman": {
          avatar:
            "https://onbeing.org/wp-content/uploads/2023/10/1200x1200-OnBeing-ReidHoffman-EpisodePage-copy.png",
          bio: "Reid Hoffman is a visionary entrepreneur and influential thought leader, renowned for his innovative contributions to the startup ecosystem. As the CEO of several successful ventures, Reid has demonstrated expertise in scaling companies from inception to industry leadership. With a keen eye for identifying market opportunities and a profound understanding of technology and business strategy, he has catalyzed transformative growth in the startup community. Reid's legacy includes co-founding LinkedIn, guiding it to become the world's premier professional networking platform, and his continued influence is evident through his strategic advisory roles and impactful investments. His commitment to fostering entrepreneurship and shaping the future of business makes him an unparalleled expert in his role as CEO.",
        },
      },
      {
        "Tim Ferris": {
          avatar: "https://miro.medium.com/v2/resize:fit:400/0*AiyNlk7NRAlQWbjt.png",
          bio: "Tim Ferriss, the dynamic CEO of an innovative startup, is a renowned expert in the entrepreneurial world. With a knack for maximizing efficiency and driving growth, Tim has built a career out of transforming ambitious ideas into successful ventures. His strategic vision and hands-on approach have consistently led to groundbreaking achievements and industry recognition. An author, investor, and public speaker, Tim is celebrated for his unconventional thinking and commitment to empowering entrepreneurs to reach their full potential.",
        },
      },
      {
        "Gary Vaynerchuck": {
          avatar:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGnP4oCbOFh-3dpjj19LIo0T_Vs3whmEmftg&s",
          bio: "Gary Vaynerchuk, CEO of startup VaynerX, is a renowned entrepreneur, author, and internet personality with a proven track record of success in digital marketing and business growth. As the driving force behind several successful ventures, Gary's expertise lies in leveraging social media and emerging technologies to build robust brand identities and drive business innovation. His forward-thinking approach and relentless energy have positioned him as a thought leader in the startup ecosystem, inspiring countless entrepreneurs to think outside the box. With a passion for hustle and a knack for spotting trends, Gary continues to redefine what it means to be a leader in the digital age.",
        },
      },
    ],
    prompts: [
      "Best practices for remote team management?",
      "Key metrics for tracking growth?",
      "Key indicators of product-market fit?",
    ],
  },
  "Venture Capitalist": {
    experts: [
      {
        "Fred Wilson": {
          avatar: "https://thetechpanda.com/wp-content/uploads/2013/02/fredwilson.jpg",
          bio: "Fred Wilson is a distinguished venture capitalist known for his astute investment acumen and strategic foresight. With over three decades in the industry, he has an impressive track record of identifying and nurturing disruptive startups that have grown into industry leaders. As a founding partner at Union Square Ventures, Fred has played a pivotal role in pioneering investments in companies across diverse sectors including technology, finance, and media. His deep understanding of market trends, coupled with his commitment to fostering innovation, has solidified his reputation as a thought leader in the venture capital community. Passionate about entrepreneurship, Fred is also an active blogger and mentor, sharing his invaluable insights with aspiring founders and investors alike.",
        },
      },
      {
        "Aileen Lee": {
          avatar:
            "https://imageio.forbes.com/specials-images/imageserve/64c2f1f64d2ca4711216a805/0x0.jpg?format=jpg&crop=1080,1080,x0,y0,safe&height=416&width=416&fit=bounds",
          bio: 'Aileen Lee is a renowned venture capitalist and founder of Cowboy Ventures, a seed-stage venture fund. With a keen eye for identifying groundbreaking startups, Aileen has been instrumental in the early success of numerous tech innovators. An influential figure in Silicon Valley, she coined the term "unicorn" to describe privately-held startups valued at over $1 billion. Beyond her investment acumen, Aileen is a passionate advocate for diversity and inclusion in the tech industry, continuously working to foster equitable opportunities for underrepresented groups.',
        },
      },
      {
        "Mark Suster": {
          avatar:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/MarkSuster2019.jpg/220px-MarkSuster2019.jpg",
          bio: "Mark Suster is a seasoned venture capitalist with a wealth of experience in the startup ecosystem. As a Managing Partner at Upfront Ventures, Mark leverages his extensive background as a two-time entrepreneur to identify and nurture groundbreaking companies. His strategic insights and hands-on approach have been instrumental in driving success for numerous startups across various industries. With a keen eye for innovation and a deep understanding of the challenges faced by entrepreneurs, Mark is a trusted advisor and a leading voice in the venture capital community.",
        },
      },
    ],
    prompts: [
      "Next big trend in SaaS?",
      "Top metrics to assess startup potential?",
      "Best advice for first-time founders?",
    ],
  },
  "Product Manager": {
    experts: [
      {
        "Ken Norton": {
          avatar:
            "https://images.ctfassets.net/h6luvadnbip0/7xXMvsqQjhMWPEj6q6wHmi/ce3cd6919dd1a33e5e42b7f1dd8f7e02/image6.png",
          bio: "Ken Norton is a seasoned Product Manager with over two decades of experience in the tech industry. Renowned for his strategic vision and innovative approach, Ken has successfully led product teams at prominent companies, driving the development and market success of numerous groundbreaking products. Known for his exceptional ability to bridge the gap between technical teams and customer needs, Ken has a proven track record in transforming complex challenges into streamlined, user-centric solutions. His thought leadership in product management is widely recognized through his influential writings and speaking engagements, making him a trusted voice and mentor in the field.",
        },
      },
      {
        "Julie Zhuo": {
          avatar:
            "https://static1.squarespace.com/static/52463a7ae4b03b8157ca5c0a/t/52a3c9c4e4b06e67e5549fe6/1386465734034/?format=1500w",
          bio: "Julie Zhuo is a distinguished Product Manager with an exceptional track record of driving innovation and growth. With over a decade of experience in the tech industry, Julie has mastered the art of transforming user insights into groundbreaking products that delight customers worldwide. She is best known for her ability to seamlessly integrate strategic vision with meticulous execution, fostering cross-functional collaboration to deliver high-impact results. Julie's leadership is characterized by her empathetic approach and her commitment to nurturing talent, making her a respected mentor and thought leader in product management.",
        },
      },
      {
        "Marty Cagan": {
          avatar:
            "https://res.cloudinary.com/bizzaboprod/image/upload/c_crop,g_custom,f_auto/v1619091539/ljgs1vvexltaefif11ih.png",
          bio: 'Marty Cagan is a renowned product management expert and partner at Silicon Valley Product Group. With decades of experience in leading top-tier product teams at companies like eBay and Netscape, Marty has revolutionized the field by advocating for customer-centric, innovative product development. An acclaimed author, his seminal work "Inspired: How To Create Products Customers Love" has become a must-read for product managers worldwide. Marty\'s deep expertise and thought leadership continue to shape the future of product management, making him a sought-after speaker, advisor, and mentor in the tech industry.',
        },
      },
    ],
    prompts: [
      "How to boost user engagement quickly?",
      "Tips for effective product roadmap planning?",
      "Best practices for MVP development?",
    ],
  },
  "Product Marketer": {
    experts: [
      {
        "Matthew Barby": {
          avatar:
            "https://www.searchenginejournal.com/wp-content/uploads/2016/03/matthewbarby-crop.jpg",
          bio: "Matthew Barby is a seasoned Product Marketer with over a decade of experience in crafting strategic marketing initiatives that drive brand growth and customer engagement. With a proven track record in both start-ups and established enterprises, Matthew excels in product positioning, market segmentation, and data-driven marketing campaigns. His innovative approach and deep understanding of consumer behavior have consistently delivered measurable results, making him a respected thought leader in the industry. Passionate about leveraging technology to enhance marketing efforts, Matthew continues to push the boundaries of what's possible in product marketing.",
        },
      },
      {
        "Nadya Khoja": {
          avatar:
            "https://pbs.twimg.com/profile_images/1090304202516111363/WS9JXzeb_400x400.jpg",
          bio: "Nadya Khoja is a seasoned Product Marketer renowned for her strategic acumen and innovative approach to market positioning. With a strong background in content marketing, data-driven decision making, and cross-functional team leadership, Nadya excels at launching products that resonate with target audiences and drive significant business growth. Her ability to blend creative storytelling with analytical insights makes her a valuable asset to any organization looking to excel in competitive markets.",
        },
      },
      {
        "Noah Kagan": {
          avatar:
            "https://cdn.prod.website-files.com/63b9c43cf803b0331540fa68/6462b12f1e4c7cc49ec898eb_noahkagan-1080.png",
          bio: "Noah Kagan is a renowned Product Marketer with over a decade of experience in the tech and digital marketing industries. Known for his strategic insights and innovative approaches, Noah has successfully propelled multiple startups to success, including AppSumo and Sumo. His expertise lies in growth hacking, user acquisition, and product optimization, making him a sought-after advisor and thought leader in the marketing community. Through his blog and podcast, Noah shares invaluable tips and lessons with aspiring marketers, establishing himself as a trusted authority in product marketing.",
        },
      },
    ],
    prompts: [
      "Top tools for product marketers?",
      "Key metrics for measuring product success?",
      "How to boost user engagement rapidly?",
    ],
  },
  "Product Designer - SaaS": {
    experts: [
      {
        "Julie Zhuo": {
          avatar:
            "https://static1.squarespace.com/static/52463a7ae4b03b8157ca5c0a/t/52a3c9c4e4b06e67e5549fe6/1386465734034/?format=1500w",
          bio: "Julie Zhuo is a renowned Product Designer specializing in SaaS (Software as a Service) solutions. With a keen eye for user experience and a profound understanding of digital product development, Julie has played a pivotal role in shaping intuitive and impactful software products. Her expertise spans from initial concept and design to execution, ensuring each product not only meets business objectives but also delivers a seamless user experience. A thought leader in the industry, Julie is dedicated to fostering innovation and excellence in every project she undertakes.",
        },
      },
      {
        "Nick Babich": {
          avatar:
            "https://static.wixstatic.com/media/5e5a34_a87562fb314d4114874e68ed341fec0d~mv2.png/v1/fill/w_668,h_668,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/Nick%20Babich.png",
          bio: "Nick Babich is a seasoned Product Designer specializing in Software as a Service (SaaS) applications. With over a decade of experience in the field, Nick combines his deep understanding of user experience principles with cutting-edge design practices to create intuitive and impactful digital products. His innovative design solutions and keen eye for detail have consistently driven user engagement and satisfaction, making him a sought-after expert in the SaaS industry. Nick's passion for design extends beyond his professional work; he is an active mentor and thought leader, sharing invaluable insights through speaking engagements and published articles.",
        },
      },
      {
        "Ken Norton": {
          avatar:
            "https://images.ctfassets.net/h6luvadnbip0/7xXMvsqQjhMWPEj6q6wHmi/ce3cd6919dd1a33e5e42b7f1dd8f7e02/image6.png",
          bio: "Ken Norton is a seasoned Product Designer with extensive experience in the SaaS industry. With a keen eye for user-centric design and a passion for crafting seamless digital experiences, Ken has successfully led numerous projects from conception to launch. His innovative approach and deep understanding of both design principles and market needs make him a sought-after expert in creating intuitive, high-performing software solutions. Ken's dedication to excellence and his ability to continuously adapt to evolving technology trends underscore his reputation as a visionary in the field of SaaS product design.",
        },
      },
    ],
    prompts: [
      "Best practices for A/B testing in SaaS?",
      "Key metrics for user engagement?",
      "How to optimize user onboarding experience?",
    ],
  },
  "Head of Sales - SaaS": {
    experts: [
      {
        "Jason Lemkin": {
          avatar:
            "https://images.squarespace-cdn.com/content/v1/61f2dfab6579ab431810cdf9/1643657986745-BCVI3KUPSKZ7GAPLEYH6/Jason-Lemkin_2.jpg",
          bio: "Jason Lemkin is a renowned expert in SaaS sales with over two decades of experience driving growth and elevating performance in the software-as-a-service industry. As the Head of Sales, Jason has consistently demonstrated a unique ability to scale businesses, optimize sales strategies, and lead high-performing teams. His deep understanding of SaaS dynamics, coupled with a relentless commitment to innovation and customer success, has positioned him as a thought leader and trusted advisor in the space. Whether steering startups to market dominance or enhancing the capabilities of established enterprises, Jason’s expertise and visionary approach make him an invaluable asset to any organization.",
        },
      },
      {
        "Steli Efti": {
          avatar:
            "https://secure.gravatar.com/avatar/7d9376024abc4b0a26a790edf2bb16df?s=400&d=mm&r=g",
          bio: "Steli Efti is a seasoned expert in the SaaS industry, currently serving as Head of Sales. With a dynamic approach and over a decade of hands-on experience, Steli has a proven track record of driving significant revenue growth and building high-performance sales teams. He is renowned for his deep understanding of the intricacies of SaaS sales, his strategic mindset, and his relentless drive to achieve excellence. Steli's leadership and innovative strategies have equipped countless companies with the tools and knowledge to scale successfully in a competitive market.",
        },
      },
      {
        "Brian Balfour": {
          avatar:
            "https://images.squarespace-cdn.com/content/v1/528c45f9e4b06be250a9fe30/1498932366746-9CAAPIXLHLN1H0CEAJ1D/image-asset.png",
          bio: "Brian Balfour is a distinguished leader in the SaaS industry, serving as the Head of Sales. With a dynamic career underpinned by extensive experience and a proven track record, Brian excels in devising and executing strategic sales initiatives that drive exponential growth. His expertise lies in understanding market dynamics, fostering strong client relationships, and guiding high-performance sales teams. Brian's commitment to innovation and excellence makes him a pivotal force in shaping the future of SaaS sales.",
        },
      },
    ],
    prompts: [
      "Strategies for reducing customer churn effectively?",
      "Best practices for scaling the sales team?",
      "Optimizing sales funnels for higher conversions?",
    ],
  },
}

import { BotSuggestionsProvider } from "@/chat/channel/BotSuggestionsContext"
import ChatChannelInputContent from "@/chat/channel/page/content/ChatChannelInputContent"
import ChatChannelThreadContent from "@/chat/channel/page/content/ChatChannelThreadContent"
import { ThreadPageContentSkeleton } from "@/chat/channel/page/content/ThreadPageContent"
import { ChatChannelPageContentQuery } from "@/chat/channel/page/content/__generated__/ChatChannelPageContentQuery.graphql"
import { useStreamChannel } from "@/core/context/StreamChatContext"
import NotFoundPageContent from "@/core/route/component/not-found/NotFoundPageContent"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import PageContent from "@/main/page/content/PageContent"
import Relay from "@/relay/relayUtils"
import AIBanner from "@components/ai/AIBanner"
import useCanTriggerAIMessageSuggestion from "@components/ai/hooks/useCanTriggerAIMessageSuggestion"
import ChatChannel from "@components/chat/channel/ChatChannel"
import CreateMessageWithAIButton from "@components/chat/channel/CreateMessageWithAIButton"
import ChatChannelHeader from "@components/chat/channel/detail/header/ChatChannelHeader"
import ChatChannelSimpleMessage from "@components/chat/channel/detail/message/ChatChannelMessage"
import { groupStyles, MESSAGE_ACTIONS } from "@components/chat/util/chatUtils"
import { DiscoSection } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { useParams } from "react-router"
import { graphql } from "relay-runtime"
import { VirtualizedMessageList, Window } from "stream-chat-react"

export type ChatChannelPageContentParams = {
  channelId: string
}

const ChatChannelPageContent = observer(() => {
  const { channelId } = useParams<ChatChannelPageContentParams>()
  const classes = useStyles()
  const [messageSent, setMessageSent] = useState<boolean>(false) // Whether or not a message was sent after initial render of this component

  const { node } = useLazyLoadQuery<ChatChannelPageContentQuery>(
    graphql`
      query ChatChannelPageContentQuery($id: ID!) {
        node(id: $id) {
          ... on ChatChannel {
            __typename
            id
            kind
            externalChannelId
            hasBotSuggestionsEnabled
            isInactive
            product {
              slug
            }
            app {
              id
              customAppTitle
              customAppDescription
              ...ChatChannelBadgeFragment
            }
            ...ChatChannelFragment
            ...ChatChannelHeaderFragment
          }
        }
      }
    `,
    { id: channelId }
  )
  const chatChannel = Relay.narrowNodeType(node, "ChatChannel")

  const canTriggerAISuggestionForEntity = useCanTriggerAIMessageSuggestion({
    channelId,
  })
  const streamChannel = useStreamChannel(chatChannel?.externalChannelId)

  if (!chatChannel || !["default", "custom"].includes(chatChannel.kind)) {
    return <NotFoundPageContent />
  }

  return (
    <PageContent
      classes={{
        main: classes.main,
        container: classes.pageContainer,
        content: classes.pageContent,
      }}
    >
      <ChatChannel
        key={chatChannel.externalChannelId}
        externalChannelId={chatChannel.externalChannelId}
        chatChannelKey={chatChannel}
      >
        <BotSuggestionsProvider
          chatChannelId={chatChannel.id}
          isPollingEnabled={chatChannel.hasBotSuggestionsEnabled}
        >
          {/* header must be wrapped by chat channel context */}
          <ChatChannelHeader chatChannelKey={chatChannel} />
          <div className={classes.messagePanel}>
            <Window>
              <DiscoSection className={classes.chatWindowPaper}>
                <VirtualizedMessageList
                  messageActions={MESSAGE_ACTIONS}
                  Message={ChatChannelSimpleMessage}
                  groupStyles={groupStyles}
                  shouldGroupByUser
                  disableDateSeparator={false}
                  stickToBottomScrollBehavior={"auto"}
                  additionalVirtuosoProps={{
                    defaultItemHeight: 64,
                    followOutput: () => true,
                  }}
                />

                {canTriggerAISuggestionForEntity &&
                  chatChannel.isInactive &&
                  !messageSent &&
                  (streamChannel ? streamChannel.state.messages.length > 0 : false) && ( // Don't show this banner while showing an empty state
                    <div className={classes.aiBannerContainer}>
                      <AIBanner
                        testid={"ChatChannelPageContent.create-message-with-ai-banner"}
                        message={"Generate a new wave of engagement!"}
                        buttons={
                          <CreateMessageWithAIButton
                            channelId={chatChannel.id}
                            template={"inactive"}
                          />
                        }
                      />
                    </div>
                  )}

                <ChatChannelInputContent
                  chatChannelId={chatChannel.id}
                  setMessageSent={setMessageSent}
                />
              </DiscoSection>
            </Window>
            <ChatChannelThreadContent chatChannelId={chatChannel.id} />
          </div>
        </BotSuggestionsProvider>
      </ChatChannel>
    </PageContent>
  )
})

const useStyles = makeUseStyles((theme) => ({
  main: {
    overflow: "hidden",
  },
  chatWindowPaper: {
    padding: 0,
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: 0,
  },
  pageContainer: {
    height: "100%",
    overflow: "hidden",
    marginBottom: 0,
    margin: 0,
  },
  pageContent: {
    margin: 0,
    maxWidth: "unset",
  },
  messagePanel: {
    position: "relative",
    display: "flex",
    height: "100%",
    overflow: "hidden",
    gap: 0,
  },
  aiBannerContainer: {
    padding: theme.spacing(0, 2.5),
  },
}))

export default Relay.withSkeleton({
  component: ChatChannelPageContent,
  skeleton: ThreadPageContentSkeleton,
})

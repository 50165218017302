import BrainSearchMessageExpertResponse from "@/brain-search/internal/BrainSearchMessageExpertResponse"
import { BrainSearchMessage } from "@/brain-search/internal/BrainSearchPage"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"

interface Props extends TestIDProps {
  message: BrainSearchMessage
}

function BrainSearchMessageExpertResponseList({ message }: Props) {
  const classes = useStyles()
  const theme = useTheme()

  const expertResponses = Relay.connectionToArray(message.expertResponses)

  if (isEmpty()) return null

  return (
    <div>
      <div className={classes.responseTag}>
        <DiscoIcon
          icon={"graduation-cap"}
          width={16}
          height={16}
          color={theme.palette.text.primary}
          active
        />
        <DiscoText variant={"heading-xs-700"}>{"What the experts say"}</DiscoText>
      </div>

      <div className={classes.expertResponsesList}>
        {expertResponses.map((expertResponse, index) => (
          <BrainSearchMessageExpertResponse
            key={expertResponse.expert}
            expertResponse={expertResponse}
            index={index}
          />
        ))}
      </div>
    </div>
  )

  function isEmpty() {
    if (!expertResponses?.length) return true
    if (!expertResponses.filter((er) => Boolean(er.text.length)).length) return true
    return false
  }
}

const useStyles = makeUseStyles((theme) => ({
  expertResponsesList: {
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderSmall,
    boxShadow: theme.palette.groovyDepths.xs,
    padding: theme.spacing(2),
  },
  responseTag: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))

export default BrainSearchMessageExpertResponseList

import { CommentUtils_useTotalCommentsCount$key } from "@/comments/utils/__generated__/CommentUtils_useTotalCommentsCount.graphql"
import { CommentUtils_useTotalCommentsOnUsageCount$key } from "@/comments/utils/__generated__/CommentUtils_useTotalCommentsOnUsageCount.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

namespace CommentUtils {
  export function getUnknownUserFullName() {
    return "Unknown User"
  }

  /** Get the total number of comments (including replies) for a content */
  export function useTotalCommentsCount(
    contentKey: CommentUtils_useTotalCommentsCount$key | null,
    contentUsageKey?: CommentUtils_useTotalCommentsOnUsageCount$key | null
  ): number {
    const content = useFragment<CommentUtils_useTotalCommentsCount$key>(
      graphql`
        fragment CommentUtils_useTotalCommentsCount on Content {
          totalComments
        }
      `,
      contentKey
    )

    const contentUsage = useFragment<CommentUtils_useTotalCommentsOnUsageCount$key>(
      graphql`
        fragment CommentUtils_useTotalCommentsOnUsageCount on ContentUsage {
          totalComments
        }
      `,
      contentUsageKey || null
    )

    return content?.totalComments || contentUsage?.totalComments || 0
  }

  export function scrollToNewComment(commentId: GlobalID) {
    setTimeout(() => {
      const newCommentEl = document.getElementById(commentId)
      newCommentEl?.scrollIntoView({ behavior: "smooth" })
    }, 100)
  }
}

export default CommentUtils

/**
 * @generated SignedSource<<d1df543c383914d86b505ed0bd9e7155>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentUtils_useTotalCommentsCount$data = {
  readonly totalComments: number;
  readonly " $fragmentType": "CommentUtils_useTotalCommentsCount";
};
export type CommentUtils_useTotalCommentsCount$key = {
  readonly " $data"?: CommentUtils_useTotalCommentsCount$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentUtils_useTotalCommentsCount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentUtils_useTotalCommentsCount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalComments",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "1c2faa4d3af4930fcdc500f8a2ba276a";

export default node;

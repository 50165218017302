import CommunityDirectMessagesPageContent from "@/admin/community/direct-messages/CommunityDirectMessagesPageContent"
import SidebarContactAdminsCarousel from "@/chat/sidebar/contact-admins/SidebarContactAdminsCarousel"
import makeUseStyles from "@assets/style/util/makeUseStyles"

export default function DirectMessagesSidebar() {
  const classes = useStyles()

  return (
    <>
      <div className={classes.contactContainer}>
        <SidebarContactAdminsCarousel />
      </div>
      <CommunityDirectMessagesPageContent />
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  contactContainer: {
    padding: theme.spacing(0, 2, 1.5),
  },
}))

import { BrainSearchMessageSourceSkeleton } from "@/brain-search/internal/BrainSearchMessageInternalSource"
import { BrainSearchMessage } from "@/brain-search/internal/BrainSearchPage"
import CertifiedBadge from "@/core/ui/iconsax/bold/verify.svg"
import { NodeFromConnection } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { lighten } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

interface Props extends TestIDProps {
  source: NodeFromConnection<BrainSearchMessage["externalSources"]>
}

function BrainSearchMessageExternalSource({ source }: Props) {
  const classes = useStyles()

  if (showSkeleton()) return <BrainSearchMessageSourceSkeleton />

  return (
    <DiscoContainerButton
      key={source.url}
      className={classNames(classes.source, classes.fadeIn)}
      onClick={() => handleClickExternalSource(source.url)}
    >
      <div className={classes.topSource}>
        <DiscoText variant={"body-xs-700"} color={"text.primary"} truncateText={3}>
          {source.title}
        </DiscoText>

        {source.isCertified && (
          <CertifiedBadge className={classes.certifiedBadge} width={16} height={16} />
        )}
      </div>
      <div className={classes.bottomSource}>
        <div className={classes.expert}>
          <img
            alt={""}
            src={`https://www.google.com/s2/favicons?domain=${
              new URL(source.url).hostname
            }`}
            className={classes.expertAvatar}
          />
          <DiscoText variant={"body-xs"} color={"text.secondary"} truncateText={1}>
            {source.owner}
          </DiscoText>
        </div>
      </div>
    </DiscoContainerButton>
  )

  function handleClickExternalSource(url: string) {
    window.open(url, "_blank")
  }

  function showSkeleton() {
    if (!source) return true
    if (!source.title) return true
    if (!source.owner) return true
    return false
  }
}

const useStyles = makeUseStyles((theme) => ({
  source: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.groovy.neutral[100],
    border: theme.palette.constants.borderSmall,
    minHeight: "80px",

    "&:hover": {
      borderColor: "#4D47DC",
      backgroundColor: lighten("#4D47DC", 0.9),
      boxShadow:
        "0px 2px 4px 0px rgba(63, 63, 70, 0.04), 0px 6px 18px 0px rgba(63, 63, 70, 0.08)",
    },
  },
  topSource: {
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
    flex: 1,
    gap: theme.spacing(1),
  },
  certifiedBadge: {
    color: theme.palette.primary.main,
    right: 0,
    flexShrink: 0,
  },
  bottomSource: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  expert: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
    width: "100%",
  },
  expertAvatar: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    background:
      "radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%), var(--Colors-blue-600, #4D47DC)",
  },
  fadeIn: {
    animation: "$fadeIn 1s",
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}))

export default BrainSearchMessageExternalSource

import { useCommentsSectionContext } from "@/comments/CommentsSectionContext"
import { CommentEditorMutation } from "@/comments/editor/__generated__/CommentEditorMutation.graphql"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabels } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import styleIf from "@assets/style/util/styleIf"
import DiscoEditor from "@components/editor/DiscoEditor"
import EditorUtils from "@components/editor/EditorUtils"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { DiscoAlert, DiscoButton, DiscoIconButton, DiscoLink, DiscoText } from "@disco-ui"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { useTheme } from "@material-ui/core"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { generatePath } from "react-router-dom"
import ConnectionHandlerPlus from "relay-connection-handler-plus"
import { commitLocalUpdate, graphql } from "relay-runtime"

// DESC -> Newest, ASC -> Oldest
export type CommentsQueryParams = { commentOrder?: "DESC" | "ASC" }

const ENFORCED_MAX_HEIGHT = 320
const ENFORCED_THRESHOLD = 60

/** User data needed to trigger a reply to a comment by that user and mention them */
export type CommentReplyTo = {
  id: GlobalID
  fullName: string
}

type CommentEditorProps = {
  onCommentSubmitted?: (commentId: GlobalID) => void
  placeholder?: string
  classes?: {
    container?: string
    editor?: string
  }
  collapsed?: boolean
  testid?: string
  replyTo?: CommentReplyTo
  contentUsageId?: GlobalID | null
  enforceMaxHeight?: boolean
  onMount?: () => void
  contentId: GlobalID
}

function CommentEditor(props: CommentEditorProps) {
  const {
    contentId,
    onCommentSubmitted,
    placeholder = "Write a comment...",
    classes: propsClasses,
    collapsed = false,
    enforceMaxHeight = false,
    testid,
    contentUsageId,
  } = props

  const { replyTo, replyParentCommentId, clearCommentReply, setSubmittingCommentId } =
    useCommentsSectionContext()

  const activeProduct = useActiveProduct()
  const labels = useLabels()
  const [params] = useQueryParamState<CommentsQueryParams>()

  const editorContainerRef = useRef<HTMLDivElement>(null)

  const [isMaxHeightEnforced, setIsMaxHeightEnforced] = useState(false)

  useEffect(() => {
    if (!editorContainerRef.current || !enforceMaxHeight) return
    const resizeObserver = new ResizeObserver(() => {
      if (!editorContainerRef.current) return
      // Only enforce max height once there is more than one line of editor content to avoid cutting off formatting toolbar
      setIsMaxHeightEnforced(
        editorContainerRef.current.clientHeight > ENFORCED_MAX_HEIGHT - ENFORCED_THRESHOLD
      )
    })
    resizeObserver.observe(editorContainerRef.current)
    return () => resizeObserver.disconnect()
  }, [enforceMaxHeight])

  const classes = useStyles({ isMaxHeightEnforced })
  const theme = useTheme()

  const { authUser } = useAuthUser({ required: true })

  const form = useFormStore<CommentEditorMutation>(
    graphql`
      mutation CommentEditorMutation($input: CreateCommentInput!) {
        response: createComment(input: $input) {
          node {
            id
            contentUsageId
            # Refetch parent comment subscription state when you reply
            parentComment {
              content {
                viewerIsSubscribed
              }
              ...CommentsListItem_comment
            }
            parentContent {
              totalTopLevelComments: totalComments(excludeReplies: true)
              ...CommentUtils_useTotalCommentsCount
            }
            contentUsage {
              totalTopLevelComments: totalComments(excludeReplies: true)
              ...CommentUtils_useTotalCommentsOnUsageCount
            }
            ...CommentsListItem_comment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      parentContentId: contentId,
      parentContentUsageId: contentUsageId,
      content: {
        richEditorDescriptionContent: JSON.stringify(EditorUtils.createParagraphs([""])),
      },
    }
  )

  useEffect(() => {
    if (replyParentCommentId) {
      form.state.parentContentId = null
      form.state.parentCommentId = replyParentCommentId
    } else {
      form.state.parentContentId = contentId
      form.state.parentCommentId = null
    }
  }, [replyParentCommentId, contentId, form.state])

  const isCommentEmpty = EditorUtils.isEmpty(
    form.state.content?.richEditorDescriptionContent
  )

  // Org admins that are not members must register first to comment.
  if (activeProduct && !activeProduct.viewerMembership) {
    return (
      <div className={propsClasses?.container}>
        <DiscoAlert
          severity={"warning"}
          data-testid={`${testid}.not-a-member-alert`}
          message={
            <DiscoText variant={"body-sm"} component={"span"}>
              {`You must be a ${labels.product_member.singular} or ${labels.product_admin.singular} in ${activeProduct.name} to comment. `}
              <DiscoLink
                to={generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
                  productSlug: activeProduct.slug,
                })}
              >
                {"Click here"}
              </DiscoLink>
              {" to register."}
            </DiscoText>
          }
        />
      </div>
    )
  }

  return (
    <div className={propsClasses?.container}>
      {/* If reply to then show name */}
      {replyTo && (
        <div className={classes.replyToContainer}>
          <DiscoText variant={"body-sm-500"}>
            {`Replying to ${replyTo?.fullName}`}
          </DiscoText>
          <DiscoIconButton
            onClick={clearCommentReply}
            size={"small"}
            testid={`${testid}.close-button`}
          >
            <CloseIcon />
          </DiscoIconButton>
        </div>
      )}
      {/* Rich Text Editor */}
      <div
        ref={editorContainerRef}
        data-testid={
          testid ? `${testid}.CommentsEditor.container` : "CommentsEditor.container"
        }
        className={classNames(classes.editorContainer)}
      >
        <DiscoEditor
          key={replyParentCommentId}
          className={classNames(classes.editor, propsClasses?.editor)}
          textColor={theme.palette.text.primary}
          onChange={handleRichEditorChange}
          minHeight={collapsed ? 24 : 72}
          placeholder={replyTo ? "Write a reply..." : placeholder}
          config={"comment"}
          onMount={props.onMount}
          // If you are replying to a comment from someone else we auto-mention that user
          defaultMentions={
            replyTo && replyTo.id !== authUser.id ? [{ user: replyTo }] : undefined
          }
        />
      </div>
      <div className={classes.buttonContainer}>
        <DiscoButton
          testid={"Comments.submit-comment-button"}
          disabled={isCommentEmpty || form.isSubmitting}
          className={classes.submitButton}
          shouldDisplaySpinner={form.isSubmitting}
          onClick={handleSubmitComment}
        >
          {replyTo ? "Reply" : "Comment"}
        </DiscoButton>
      </div>
    </div>
  )

  function handleRichEditorChange(v: string) {
    form.state.content!.richEditorDescriptionContent = v
  }

  async function handleSubmitComment() {
    if (isCommentEmpty) {
      displayErrorToast("Sorry, you can't post an empty comment.")
      return
    }

    const { didSave, response } = await form.submit(form.state)

    if (!didSave) return

    const commentId = response?.node?.id

    // clear comment box content
    clearCommentReply()

    // If this is a new top-level comment insert it into paginated comments list
    if (!replyParentCommentId && commentId) {
      // look at context tsate
      commitLocalUpdate(RelayEnvironment, (store) => {
        let parentRecord

        if (form.state.parentContentUsageId) {
          parentRecord = store.get(form.state.parentContentUsageId)
        } else {
          parentRecord = store.get(form.state.parentContentId!)
        }

        if (parentRecord) {
          ConnectionHandlerPlus.getConnections(
            parentRecord,
            "CommentsSection__comments"
          ).forEach((connection) => {
            const index =
              // If we are sorting by the oldest comments, we need to insert the comment at the end
              params.commentOrder === "ASC"
                ? (response.node?.contentUsage?.totalTopLevelComments ||
                    response.node?.parentContent.totalTopLevelComments ||
                    0) - 1
                : 0

            Relay.insertNodeIntoPaginatedConnection(
              store,
              connection,
              store.get(commentId)!,
              index
            )
          })
        }
      })
    }
    setSubmittingCommentId(replyParentCommentId!)
    if (onCommentSubmitted) onCommentSubmitted(commentId!)
  }
}

type StyleProps = {
  isMaxHeightEnforced: boolean
}

const useStyles = makeUseStyles((theme) => ({
  editorContainer: ({ isMaxHeightEnforced }: StyleProps) => ({
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.medium,

    ...styleIf(isMaxHeightEnforced, {
      maxHeight: ENFORCED_MAX_HEIGHT,
      overflowY: "auto",
    }),
  }),
  editor: {
    backgroundColor: theme.palette.groovy.neutral[100],
    padding: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(1),
    gap: theme.spacing(1),
  },
  submitButton: {
    width: "120px",
  },
  replyToContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing(2),
  },
}))

export default observer(CommentEditor)

import BookmarkButton from "@/bookmarks/BookmarkButton"
import { BookmarkKind } from "@/bookmarks/__generated__/BookmarkButtonCreateMutation.graphql"
import { CommentMoreActionBookmarkButtonQuery } from "@/comments/list/item/more-actions-dropdown/bookmark-button/__generated__/CommentMoreActionBookmarkButtonQuery.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoTextSkeleton } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql, useLazyLoadQuery } from "react-relay"

interface CommentMoreActionBookmarkButtonProps extends TestIDProps {
  contentId: string
  kind: BookmarkKind
}

function CommentMoreActionBookmarkButton({
  contentId,
  kind,
  testid,
}: CommentMoreActionBookmarkButtonProps) {
  const classes = useStyles()
  const { content } = useLazyLoadQuery<CommentMoreActionBookmarkButtonQuery>(
    graphql`
      query CommentMoreActionBookmarkButtonQuery($id: ID!) {
        content: node(id: $id) {
          ... on Content {
            hasViewerBookmarked
          }
          ...BookmarkButtonContentFragment
        }
      }
    `,
    { id: contentId }
  )

  if (!content) return null

  return (
    <BookmarkButton contentKey={content} kind={kind} testid={testid}>
      {(buttonProps) => (
        <DiscoDropdownItem
          {...buttonProps}
          className={classes.dropdownItem}
          data-testid={`${buttonProps.testid}.DiscoDropdownItem`}
        >
          {content.hasViewerBookmarked ? "Remove Bookmark" : "Add Bookmark"}
        </DiscoDropdownItem>
      )}
    </BookmarkButton>
  )
}

const useStyles = makeUseStyles((theme) => ({
  dropdownItem: {
    color: `${theme.palette.groovy.neutral[500]} !important`,
    fontSize: "14px",
  },
}))

export default Relay.withSkeleton({
  component: observer(CommentMoreActionBookmarkButton),
  skeleton: () => (
    <DiscoDropdownItem
      title={<DiscoTextSkeleton variant={"body-sm"} width={"175px"} />}
    />
  ),
})

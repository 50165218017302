import { BrainSearchLandingPageMutation } from "@/brain-search/internal/__generated__/BrainSearchLandingPageMutation.graphql"
import { BrainSearchLandingPageQuery } from "@/brain-search/internal/__generated__/BrainSearchLandingPageQuery.graphql"
import BrainSearchInput from "@/brain-search/internal/BrainSearchInput"
import BrainSearchPage from "@/brain-search/internal/BrainSearchPage"
import BrainSearchRoleSelection from "@/brain-search/internal/BrainSearchRoleSelection"
import BrainSearchSuggestionList from "@/brain-search/internal/BrainSearchSuggestionList"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import classNames from "classnames"
import { useLazyLoadQuery } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import { graphql, RecordSourceSelectorProxy } from "relay-runtime"

export type BrainSearchParams = {
  searchId?: GlobalID
  status?: "pending" | "completed"
}

function BrainSearchLandingPage() {
  const activeOrganization = useActiveOrganization()
  const { authUser } = useAuthUser()
  const classes = useStyles()
  const [params, setParams] = useQueryParamState<BrainSearchParams>()

  const { organizationMembership } = useLazyLoadQuery<BrainSearchLandingPageQuery>(
    graphql`
      query BrainSearchLandingPageQuery($id: ID!) {
        organizationMembership: node(id: $id) {
          ... on OrganizationMembership {
            lastBrainSearch: brainSearches(first: 1) {
              totalCount
              edges {
                node {
                  id
                  role
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization?.viewerMembership?.id || "",
    },
    { fetchPolicy: "network-only" }
  )

  const lastBrainSearch = organizationMembership?.lastBrainSearch?.totalCount
    ? Relay.connectionToArray(organizationMembership?.lastBrainSearch)[0]
    : null

  const form = useFormStore<BrainSearchLandingPageMutation>(
    graphql`
      mutation BrainSearchLandingPageMutation($input: CreateBrainSearchInput!) {
        response: createBrainSearch(input: $input) {
          node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `,
    { title: "", role: lastBrainSearch?.role || "" }
  )

  // If the searchId is present, redirect to the search page
  if (params.searchId) {
    return <BrainSearchPage initialMessage={form.state.title} />
  }

  return (
    <div className={classNames(classes.root, classes.fadeIn)}>
      <div className={classes.contentPadding}>
        <div className={classes.container}>
          {/* Headers */}
          <DiscoText variant={"heading-xxl"} color={"text.primary"} marginBottom={2}>
            {authUser?.firstName ? `Hello, ${authUser?.firstName}.` : "Hello."}
          </DiscoText>
          <DiscoText
            variant={"heading-sm"}
            color={"groovy.neutral.400"}
            marginBottom={4}
            className={classes.subHeader}
          >
            {"What would you like to learn today?"}
          </DiscoText>

          {/* Input */}
          <BrainSearchInput
            placeholder={"Ask a question..."}
            onChange={handleChangeQuery}
            onSubmit={handleSubmit}
            clearOnSubmit={false}
            classes={{ root: classes.inputRoot }}
          />

          {/* Suggestions */}
          <BrainSearchSuggestionList form={form} onSelect={handleSubmit} />
          {/* Role Selection */}
          <BrainSearchRoleSelection form={form} />
        </div>
      </div>
    </div>
  )

  function handleChangeQuery(text: string) {
    form.state.title = text
  }

  async function handleSubmit() {
    if (!form.state.title) return

    const { response, didSave } = await form.submit(
      {
        title: form.state.title,
        role: form.state.role,
      },
      {
        updater: (store, { response: r }) => {
          if (!r.errors) insertSearchIntoStore(store, r.node?.id)
        },
      }
    )
    if (!didSave || !response?.node?.id) return

    setParams({ searchId: response.node.id, status: "pending" })
  }

  function insertSearchIntoStore(
    store: RecordSourceSelectorProxy,
    brainSearchId?: string
  ) {
    if (!activeOrganization?.viewerMembership?.id) return

    // Get the organization membership record
    const orgMemRecord = store.get(activeOrganization.viewerMembership.id)
    if (!orgMemRecord) return

    // Get the sidebar connection
    const sidebarConnections = ConnectionHandler.getConnections(
      orgMemRecord,
      "BrainSearchSidebarList__brainSearches"
    )
    if (!sidebarConnections?.length) return

    // Create the new search node
    const tmpBrainSearch = Relay.fabricateNode(store, "BrainSearch", {
      id: brainSearchId,
      title: form.state.title,
    })

    // Insert the new search into the sidebar connection at the top
    for (const connection of sidebarConnections) {
      Relay.insertNodeIntoPaginatedConnection(store, connection, tmpBrainSearch, 0)
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5),
  },
  contentPadding: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0% 10%",
    height: "100%",
    backgroundColor: theme.palette.common.white,

    [theme.breakpoints.down("md")]: {
      padding: "0% 5%",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "0%",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    maxWidth: "1400px",
  },
  subHeader: {
    fontWeight: 400,
  },
  inputRoot: {
    width: "75%",
    marginBottom: theme.spacing(5),

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  fadeIn: {
    animation: "$fadeIn 0.5s",
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}))

export default BrainSearchLandingPage

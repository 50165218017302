import { CommentReplyTo } from "@/comments/editor/CommentEditor"
import { createContext, useContext, useState } from "react"

type CommmentSectionContextValue = {
  replyTo: CommentReplyTo | null
  replyParentCommentId: string | null
  submittingCommentId: string | null
  setCommentReply: (replyTo: CommentReplyTo | null, parentId: string | null) => void
  clearCommentReply: () => void
  setSubmittingCommentId: (id: string | null) => void
}

const CommentsSectionContext = createContext<CommmentSectionContextValue>({
  replyTo: null,
  replyParentCommentId: null,
  submittingCommentId: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCommentReply: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearCommentReply: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSubmittingCommentId: () => {},
})

export const CommentsSectionProvider: React.FC = ({ children }) => {
  const [submittingCommentId, setSubmittingCommentId] = useState<string | null>(null)
  const [replyTo, setReplyTo] = useState<CommentReplyTo | null>(null)
  const [replyParentCommentId, setParentCommentId] = useState<string | null>(null)

  const setCommentReply = (to: CommentReplyTo | null, parentId: string | null) => {
    setReplyTo(to)
    setParentCommentId(parentId)
  }

  const clearCommentReply = () => {
    setReplyTo(null)
    setParentCommentId(null)
  }

  return (
    <CommentsSectionContext.Provider
      value={{
        replyTo,
        replyParentCommentId,
        submittingCommentId,
        setCommentReply,
        clearCommentReply,
        setSubmittingCommentId,
      }}
    >
      {children}
    </CommentsSectionContext.Provider>
  )
}

export function useCommentsSectionContext(): CommmentSectionContextValue {
  const context = useContext(CommentsSectionContext)
  return context
}

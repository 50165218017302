import BrainSearchMessageExternalSource from "@/brain-search/internal/BrainSearchMessageExternalSource"
import BrainSearchMessageInternalSource from "@/brain-search/internal/BrainSearchMessageInternalSource"
import { BrainSearchMessage } from "@/brain-search/internal/BrainSearchPage"
import { NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { lighten, useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"

export type ExternalSource = NodeFromConnection<BrainSearchMessage["externalSources"]>
export type InternalSource = NodeFromConnection<BrainSearchMessage["sources"]>

interface Props extends TestIDProps {
  message: BrainSearchMessage
  maxSources?: number
}

function BrainSearchMessageSourceList({ message, maxSources = 4 }: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const [viewMore, setViewMore] = useState(false)
  const externalSources = Relay.connectionToArray(message.externalSources)
  const internalSources = Relay.connectionToArray(message.sources)
  const sources = [...internalSources, ...externalSources]
  const { initialSources, moreSources } = arrangeSources()

  if (!sources?.length) return null

  return (
    <div className={classes.sources}>
      <div className={classes.sourcesTag}>
        <DiscoIcon
          icon={"globe"}
          width={16}
          height={16}
          color={theme.palette.text.primary}
          active
        />
        <DiscoText variant={"heading-xs-700"}>{"Sources"}</DiscoText>
      </div>

      <div className={classes.sourcesList}>
        {getInitialSources().map((source) => {
          if (isExternalSource(source)) {
            return (
              <BrainSearchMessageExternalSource
                key={(source as ExternalSource).url}
                source={source as ExternalSource}
              />
            )
          }

          return (
            <BrainSearchMessageInternalSource
              key={(source as InternalSource).id}
              source={source as InternalSource}
            />
          )
        })}

        {/* If there are > 3 sources, add a "See More" */}
        {Boolean(moreSources.length) && (
          <DiscoContainerButton className={classes.source} onClick={toggleViewMore}>
            <div className={classes.topSource}>
              <div className={classes.expert}>
                {moreSources.slice(0, 3).map((s) => (
                  <div key={s.id} className={classes.expertAvatar} />
                ))}
              </div>
            </div>
            <div className={classes.bottomSource}>
              <DiscoText variant={"body-xs"} color={"text.secondary"}>
                {viewMore ? "See less" : `View ${moreSources.length} more`}
              </DiscoText>
            </div>
          </DiscoContainerButton>
        )}
      </div>
    </div>
  )

  function arrangeSources() {
    // If there are 4 or less sources, render normally
    if (sources.length <= maxSources) return { initialSources: sources, moreSources: [] }

    // If there are more than 4 sources, render the first 3 and a "See More" button
    return {
      initialSources: sources.slice(0, maxSources - 1),
      moreSources: sources.slice(maxSources - 1),
    }
  }

  function getInitialSources() {
    if (viewMore) return sources
    return initialSources
  }

  function toggleViewMore() {
    setViewMore((prev) => !prev)
  }

  function isExternalSource(source: ExternalSource | InternalSource) {
    return !(source as InternalSource).entityId
  }
}

const useStyles = makeUseStyles((theme) => ({
  sources: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  sourcesTag: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  sourcesList: {
    display: "grid",
    gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    gridAutoRows: "1fr",
    gap: theme.spacing(1.5),
  },
  source: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.groovy.neutral[100],
    border: theme.palette.constants.borderSmall,
    minHeight: "80px",

    "&:hover": {
      borderColor: "#4D47DC",
      backgroundColor: lighten("#4D47DC", 0.9),
      boxShadow:
        "0px 2px 4px 0px rgba(63, 63, 70, 0.04), 0px 6px 18px 0px rgba(63, 63, 70, 0.08)",
    },
  },
  topSource: {
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
    flex: 1,
  },
  bottomSource: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  expert: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
  },
  expertAvatar: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    background:
      "radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%), var(--Colors-blue-600, #4D47DC)",
  },
}))

export default BrainSearchMessageSourceList

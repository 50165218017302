import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoQueryParamTabs, { QueryParamTab } from "@disco-ui/tabs/DiscoQueryParamTabs"
import React from "react"

export type BookmarksTab = "content" | "events" | "comments" | "posts"

function BookmarksSubtabs() {
  const classes = useStyles()

  const tabs: QueryParamTab<GlobalDrawerParams<"bookmarks">>[] = [
    {
      label: "Content",
      params: { bookmarksTab: "content" },
      testid: "content",
    },
    {
      label: "Events",
      params: { bookmarksTab: "events" },
      testid: "events",
    },
    {
      label: "Comments",
      params: { bookmarksTab: "comments" },
      testid: "comments",
    },
    {
      label: "Posts",
      params: { bookmarksTab: "posts" },
      testid: "posts",
    },
  ]

  return (
    <div className={classes.subtabsContainer}>
      <div className={classes.flex}>
        <DiscoQueryParamTabs
          testid={"BookmarksSubtabs"}
          tabs={tabs}
          className={classes.subtabs}
        />
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subtabsContainer: {
    position: "relative",
  },
  subtabs: {
    textAlign: "center",
    overflow: "auto",
    ...theme.typography["body-sm"],
    ...theme.typography.modifiers.fontWeight[600],
    transform: "translateY(1.5px)",

    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
  },
}))

export default React.memo(BookmarksSubtabs)

import { StreamMessageWithDiscoData } from "@/chat/sidebar/threads/ThreadsSidebarListItem"
import CommentUtils from "@/comments/utils/CommentUtils"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useRenderChatChannelMessage from "@components/chat/channel/detail/message/useRenderChatChannelMessage"
import { formatStreamMessageDate } from "@components/chat/util/chatUtils"
import { DiscoText, DiscoTooltip } from "@disco-ui"
import { useTheme } from "@material-ui/core"

type Props = {
  message: StreamMessageWithDiscoData
  parentMessage: StreamMessageWithDiscoData
  isUnread: boolean
}

export default function ThreadLatestReplyPreview(props: Props) {
  const { message, parentMessage, isUnread } = props
  const classes = useStyles()
  const theme = useTheme()
  const user = {
    id: message.user?.disco_user_id,
    avatar: message.user?.avatar,
    fullName: message.user?.name || CommentUtils.getUnknownUserFullName(),
  }
  const dates = formatStreamMessageDate(message.created_at)
  const neutralTextColor =
    theme.palette.type === "dark" ? "groovy.neutral.600" : "groovy.neutral.400"

  // Message text can have markdown in it, so we need to use the Stream message renderer
  const renderedMessage = useRenderChatChannelMessage(message)
  const renderedParentMessage = useRenderChatChannelMessage(parentMessage)

  return (
    <div className={classes.latestReply}>
      <ProfileAvatar userKey={null} user={user} size={32} />
      <div className={classes.messageDetails}>
        <div className={classes.nameAndTime}>
          <DiscoText variant={isUnread ? "body-sm-700" : "body-sm-500"} truncateText={1}>
            {user.fullName}
          </DiscoText>
          {dates && (
            <DiscoTooltip content={dates.tooltip}>
              <DiscoText
                variant={isUnread ? "body-xs-500" : "body-xs"}
                color={neutralTextColor}
                className={classes.date}
              >
                {dates.display}
              </DiscoText>
            </DiscoTooltip>
          )}
        </div>
        <DiscoText
          variant={isUnread ? "body-xs-500" : "body-xs"}
          color={neutralTextColor}
          truncateText={1}
          className={classes.message}
        >
          {`replied to: `}
          {renderedParentMessage}
        </DiscoText>
        <DiscoText
          variant={isUnread ? "body-sm-600" : "body-sm"}
          color={isUnread ? "text.primary" : "text.secondary"}
          truncateText={2}
          className={classes.message}
        >
          {renderedMessage}
        </DiscoText>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  latestReply: {
    display: "flex",
    gap: theme.spacing(1.5),
  },
  messageDetails: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    minWidth: 0,
  },
  nameAndTime: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  date: {
    whiteSpace: "nowrap",
  },
  message: {
    // Force all rendered chat message elements to use the wrapper's text styles
    "& *": {
      display: "inline !important",
      fontSize: "inherit !important",
      lineHeight: "inherit !important",
      color: "inherit !important",
      transition: "inherit !important",
      // Don't open links when clicked in preview
      pointerEvents: "none",
    },
  },
}))

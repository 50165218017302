/**
 * @generated SignedSource<<597012b25859e35c6bacfecbc97c5b3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BrainSearchLandingPageQuery$variables = {
  id: string;
};
export type BrainSearchLandingPageQuery$data = {
  readonly organizationMembership: {
    readonly lastBrainSearch?: {
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly role: string | null;
        };
      }>;
    };
  } | null;
};
export type BrainSearchLandingPageQuery = {
  variables: BrainSearchLandingPageQuery$variables;
  response: BrainSearchLandingPageQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "lastBrainSearch",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "BrainSearchNodeConnection",
      "kind": "LinkedField",
      "name": "brainSearches",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BrainSearchNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BrainSearch",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "brainSearches(first:1)"
    }
  ],
  "type": "OrganizationMembership",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BrainSearchLandingPageQuery",
    "selections": [
      {
        "alias": "organizationMembership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BrainSearchLandingPageQuery",
    "selections": [
      {
        "alias": "organizationMembership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "902ee00c2befaa73bb496b2e3249ab00",
    "id": null,
    "metadata": {},
    "name": "BrainSearchLandingPageQuery",
    "operationKind": "query",
    "text": "query BrainSearchLandingPageQuery(\n  $id: ID!\n) {\n  organizationMembership: node(id: $id) {\n    __typename\n    ... on OrganizationMembership {\n      lastBrainSearch: brainSearches(first: 1) {\n        totalCount\n        edges {\n          node {\n            id\n            role\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "18109f727186dde5ec86d1625a63abeb";

export default node;

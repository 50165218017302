import {
  BotSuggestionsContextFragment$data,
  BotSuggestionsContextFragment$key,
} from "@/chat/channel/__generated__/BotSuggestionsContextFragment.graphql"
import { BotSuggestionsContextQuery } from "@/chat/channel/__generated__/BotSuggestionsContextQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useInterval from "@utils/hook/useInterval"
import { SECOND_IN_MS } from "@utils/time/timeConstants"
import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { useChannelStateContext, useMessageInputContext } from "stream-chat-react"

// Refetch suggestions every 30 seconds
const SUGGESTIONS_POLL_INTERVAL_MS = 30 * SECOND_IN_MS

export type BotSuggestion = NonNullable<
  NonNullable<BotSuggestionsContextFragment$data["edges"]>[0]["node"]
>

export type SelectedBotSuggestion = {
  threadId: string
  suggestion: BotSuggestion
}

interface OpenThreadProps {
  id: string
  setText: (text: string) => void
}

type BotSuggestionsContextValue = {
  botContextKey?: BotSuggestionsContextFragment$key | null
  openThread: OpenThreadProps | null
  setOpenThread: React.Dispatch<React.SetStateAction<OpenThreadProps | null>>
  selectedSuggestion?: SelectedBotSuggestion | null
  setSelectedSuggestion: React.Dispatch<
    React.SetStateAction<SelectedBotSuggestion | null>
  >
}

const BotSuggestionsContext = createContext<BotSuggestionsContextValue>({
  botContextKey: null,
  openThread: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOpenThread: () => {},
  selectedSuggestion: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedSuggestion: () => {},
})

interface BotSuggestionsProviderProps {
  children: React.ReactNode
  chatChannelId?: GlobalID | null
  isPollingEnabled?: boolean | null
}

export function BotSuggestionsProvider({
  children,
  chatChannelId,
  isPollingEnabled = false,
}: BotSuggestionsProviderProps) {
  const [openThread, setOpenThread] = useState<OpenThreadProps | null>(null)
  const [selectedSuggestion, setSelectedSuggestion] =
    useState<SelectedBotSuggestion | null>(null)

  const [{ node }, refetch] = Relay.useRefetchableQuery<BotSuggestionsContextQuery>(
    graphql`
      query BotSuggestionsContextQuery($id: ID!) {
        node(id: $id) {
          ... on ChatChannel {
            botMessages(first: 25) {
              ...BotSuggestionsContextFragment
            }
          }
        }
      }
    `,
    {
      id: chatChannelId || "",
    },
    { refetchInBackground: true }
  )

  useInterval(() => {
    if (!isPollingEnabled) return
    refetchSuggestions()
  }, SUGGESTIONS_POLL_INTERVAL_MS)

  /** Refetch suggestions in context */
  const refetchSuggestions = useCallback(() => {
    if (!chatChannelId) return
    refetch({ id: chatChannelId }, { inBackground: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatChannelId])

  return (
    <BotSuggestionsContext.Provider
      value={{
        botContextKey: node?.botMessages,
        openThread,
        setOpenThread,
        selectedSuggestion,
        setSelectedSuggestion,
      }}
    >
      {children}
    </BotSuggestionsContext.Provider>
  )
}

export default BotSuggestionsContext

export function useBotSuggestionContext() {
  return useContext(BotSuggestionsContext)
}

interface UseBotMessageInputArgs {
  disabled?: boolean
  threadId?: string
}

export function useBotMessageInput(args: UseBotMessageInputArgs) {
  const { openThread, setOpenThread, setSelectedSuggestion } = useBotSuggestionContext()

  const channelState = useChannelStateContext()

  const { setText } = useMessageInputContext()

  useEffect(() => {
    // If threadId is not provided or hook disabled, ignore
    if (!args.threadId || args.disabled) return
    // If provided threadId is not the same as the active thread, ignore
    if (args.threadId !== channelState.thread?.id) return
    // If thread is already stored in openThread, ignore
    if (args.threadId === openThread?.id) return

    setOpenThread({
      id: args.threadId,
      setText,
    })

    // On unmount, clear open thread
    return () => {
      setOpenThread(null)
      setSelectedSuggestion(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

interface UseBotThreadSuggestionArgs {
  threadId?: string
  disabled?: boolean
}

export function useBotThreadSuggestion(args: UseBotThreadSuggestionArgs) {
  const { botContextKey, openThread, setSelectedSuggestion } = useBotSuggestionContext()

  const suggestionsConnection = useFragment<BotSuggestionsContextFragment$key>(
    graphql`
      fragment BotSuggestionsContextFragment on BotQueuedMessageNodeConnection {
        edges {
          node {
            id
            messageChannel
            messageThreadTs
            messageTs
            suggestedMessage
            suggestedMentionedPlatformUserIds
            ...ChatChannelBotSuggestedMessageFragment
          }
        }
      }
    `,
    botContextKey || null
  )

  const botSuggestion = getSuggestion()

  return { botSuggestion, selectSuggestion }

  function selectSuggestion() {
    if (!botSuggestion?.suggestedMessage || !args.threadId) return

    const referencedMessage = replaceUserMentions(botSuggestion.suggestedMessage)

    if (openThread) {
      openThread.setText(referencedMessage)
    }

    setSelectedSuggestion({
      threadId: args.threadId,
      suggestion: botSuggestion,
    })
  }

  function replaceUserMentions(suggestedMessage: string) {
    const pattern = /<ChatChannelUserMarkdown streamUserId=".*?" name="(.*?)" \/>/g
    const resultString = suggestedMessage.replace(pattern, "@$1")
    return resultString
  }

  function getSuggestion() {
    if (args.disabled) return null

    const allSuggestions = Relay.connectionToArray(suggestionsConnection)

    if (!args.threadId) return null

    const threadSuggestion = allSuggestions.find((suggestion) => {
      return suggestion.messageThreadTs === args.threadId
    })

    return threadSuggestion || null
  }
}

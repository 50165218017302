/**
 * @generated SignedSource<<4e6b4129d6d4b54082f9ecf7461f0bdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BrainSearchMessageExternalSourceEntity = "website" | "video" | "image" | "%future added value";
export type BrainSearchMessageSourceEntity = "content" | "asset" | "training_data" | "%future added value";
export type BrainSearchMessageType = "user" | "assistant" | "%future added value";
export type BrainSearchPage_PaginationQuery$variables = {
  id: string;
  first: number;
  after?: string | null;
};
export type BrainSearchPage_PaginationQuery$data = {
  readonly brainSearch: {
    readonly id?: string;
    readonly title?: string;
    readonly messages?: {
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly messageText: string;
          readonly type: BrainSearchMessageType;
          readonly expertResponses: {
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly text: string;
                readonly expert: string;
              };
            }>;
          };
          readonly sources: {
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly entity: BrainSearchMessageSourceEntity;
                readonly entityId: string;
                readonly content: {
                  readonly id: string;
                  readonly name: string | null;
                } | null;
                readonly asset: {
                  readonly id: string;
                  readonly name: string;
                } | null;
                readonly trainingData: {
                  readonly id: string;
                  readonly name: string;
                  readonly url: string;
                } | null;
              };
            }>;
          } | null;
          readonly externalSources: {
            readonly totalCount: number;
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly entity: BrainSearchMessageExternalSourceEntity;
                readonly url: string;
                readonly owner: string;
                readonly title: string;
                readonly isCertified: boolean;
                readonly isExternal: boolean;
                readonly score: number;
              };
            }>;
          } | null;
          readonly suggestedFollowups: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly text: string;
              };
            }>;
          };
        };
      }>;
      readonly pageInfo: {
        readonly startCursor: string | null;
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
      };
    };
  } | null;
};
export type BrainSearchPage_PaginationQuery = {
  variables: BrainSearchPage_PaginationQuery$variables;
  response: BrainSearchPage_PaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entity",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  (v4/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "BrainSearchMessageNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BrainSearchMessage",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "messageText",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrainExpertResponseNodeConnection",
            "kind": "LinkedField",
            "name": "expertResponses",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BrainExpertResponseNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrainExpertResponse",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expert",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrainSearchMessageSourceNodeConnection",
            "kind": "LinkedField",
            "name": "sources",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BrainSearchMessageSourceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrainSearchMessageSource",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "entityId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Content",
                        "kind": "LinkedField",
                        "name": "content",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Asset",
                        "kind": "LinkedField",
                        "name": "asset",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TrainingData",
                        "kind": "LinkedField",
                        "name": "trainingData",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v9/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrainSearchMessageExternalSourceNodeConnection",
            "kind": "LinkedField",
            "name": "externalSources",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BrainSearchMessageExternalSourceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrainSearchMessageExternalSource",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v8/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "owner",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isCertified",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isExternal",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "score",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BrainSuggestedFollowupNodeConnection",
            "kind": "LinkedField",
            "name": "suggestedFollowups",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BrainSuggestedFollowupNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BrainSuggestedFollowup",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfoObjectType",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasPreviousPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v14 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BrainSearchPage_PaginationQuery",
    "selections": [
      {
        "alias": "brainSearch",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": "messages",
                "args": null,
                "concreteType": "BrainSearchMessageNodeConnection",
                "kind": "LinkedField",
                "name": "__BrainSearchPage__messages_connection",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": null
              }
            ],
            "type": "BrainSearch",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BrainSearchPage_PaginationQuery",
    "selections": [
      {
        "alias": "brainSearch",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": (v14/*: any*/),
                "concreteType": "BrainSearchMessageNodeConnection",
                "kind": "LinkedField",
                "name": "messages",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v14/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "BrainSearchPage__messages",
                "kind": "LinkedHandle",
                "name": "messages"
              }
            ],
            "type": "BrainSearch",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00101498efbdd52275e322cfe8d7a4a8",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "brainSearch",
            "messages"
          ]
        }
      ]
    },
    "name": "BrainSearchPage_PaginationQuery",
    "operationKind": "query",
    "text": "query BrainSearchPage_PaginationQuery(\n  $id: ID!\n  $first: Int!\n  $after: String\n) {\n  brainSearch: node(id: $id) {\n    __typename\n    ... on BrainSearch {\n      id\n      title\n      messages(first: $first, after: $after) {\n        totalCount\n        edges {\n          node {\n            id\n            messageText\n            type\n            expertResponses {\n              totalCount\n              edges {\n                node {\n                  id\n                  text\n                  expert\n                }\n              }\n            }\n            sources {\n              totalCount\n              edges {\n                node {\n                  id\n                  entity\n                  entityId\n                  content {\n                    id\n                    name\n                  }\n                  asset {\n                    id\n                    name\n                  }\n                  trainingData {\n                    id\n                    name\n                    url\n                  }\n                }\n              }\n            }\n            externalSources {\n              totalCount\n              edges {\n                node {\n                  id\n                  entity\n                  url\n                  owner\n                  title\n                  isCertified\n                  isExternal\n                  score\n                }\n              }\n            }\n            suggestedFollowups {\n              edges {\n                node {\n                  id\n                  text\n                }\n              }\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          startCursor\n          endCursor\n          hasNextPage\n          hasPreviousPage\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd57e6ad9b65811549cd2e1422c19f4d";

export default node;

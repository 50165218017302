import { DeleteCommentButtonMutation } from "@/comments/list/item/more-actions-dropdown/delete-button/__generated__/DeleteCommentButtonMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import React, { useState } from "react"
import { graphql, useMutation } from "react-relay"
import ConnectionHandlerPlus from "relay-connection-handler-plus"

interface DeleteCommentButtonProps {
  commentId: GlobalID
  children:
    | React.ReactElement
    | React.FC<{
        onClick(e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>): void
      }>
    | string
}

function DeleteCommentButton({
  commentId,
  children,
  ...props
}: DeleteCommentButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [commit, isDeleting] = useMutation<DeleteCommentButtonMutation>(graphql`
    mutation DeleteCommentButtonMutation($id: ID!) {
      response: deleteComment(id: $id) {
        node {
          id
          parentComment {
            ...CommentsListItem_comment
          }
          parentContent {
            id
            hasViewerCommented
            ...CommentUtils_useTotalCommentsCount
          }
          contentUsage {
            id
            hasViewerCommented
            ...CommentUtils_useTotalCommentsOnUsageCount
          }
        }
      }
    }
  `)

  return (
    <>
      {React.isValidElement(children) ? (
        <DiscoButton onClick={openModal} {...props}>
          {children}
        </DiscoButton>
      ) : (
        React.createElement(children, { onClick: openModal })
      )}

      <DiscoWarningModal
        testid={"DeleteCommentButton.delete-warning-modal"}
        isOpen={isModalOpen}
        onClose={closeModal}
        title={"Are you sure you want to delete this comment?"}
        description={"The comment will be deleted permanently."}
        confirmationButtonProps={{
          onClick: deleteComment,
          shouldDisplaySpinner: isDeleting,
          children: "Yes, delete it",
        }}
        modalContentLabel={"Delete Comment Dialog"}
      />
    </>
  )

  function deleteComment() {
    commit({
      variables: { id: commentId },
      onCompleted() {
        displaySuccessToast({
          message: "Comment deleted!",
          testid: "DeleteCommentButton.success-toast",
        })
      },
      updater: (store, payload) => {
        let parentRecord

        if (payload.response.node?.contentUsage) {
          parentRecord = store.get(payload.response.node.contentUsage.id)
        } else if (payload.response.node?.parentContent) {
          parentRecord = store.get(payload.response.node.parentContent.id)
        }

        if (!parentRecord) return

        ConnectionHandlerPlus.getConnections(
          parentRecord,
          "CommentsSection__comments"
        ).forEach((connection) => {
          ConnectionHandlerPlus.deleteNode(connection, commentId)
        })
      },
      onError(error) {
        displayErrorToast(error)
      },
    })
  }

  function openModal() {
    setIsModalOpen(true)
  }
  function closeModal() {
    setIsModalOpen(false)
  }
}

export default DeleteCommentButton

import { BrainSearchMessage } from "@/brain-search/internal/BrainSearchPage"
import { ROLES } from "@/brain-search/internal/brainSearchUtils"
import { NodeFromConnection } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import { DiscoDivider, DiscoText } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { TestIDProps } from "@utils/typeUtils"

interface Props extends TestIDProps {
  expertResponse: NodeFromConnection<BrainSearchMessage["expertResponses"]>
  index?: number
}

function BrainSearchMessageExpertResponse({ expertResponse, index = 0 }: Props) {
  const classes = useStyles()

  if (!expertResponse.text) return null

  const getExpertAvatarUrl = (expertName: string): string => {
    for (const role of Object.values(ROLES)) {
      const expert = role.experts.find((exp) => Object.keys(exp)[0] === expertName)
      if (expert) {
        return expert[expertName].avatar
      }
    }
    return ""
  }

  const avatarUrl = getExpertAvatarUrl(expertResponse.expert)

  return (
    <div key={expertResponse.expert}>
      {index > 0 && <DiscoDivider marginTop={2} />}

      <div className={classes.expert}>
        <DiscoImage src={avatarUrl} className={classes.expertAvatar} />
        <DiscoText variant={"body-xs-600"} color={"text.primary"}>
          {expertResponse.expert}
        </DiscoText>
      </div>

      <DiscoEditor
        readOnly
        disableGutter
        markdown={expertResponse.text}
        fontSize={"12px"}
        disableScroll
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  expert: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
  },
  expertAvatar: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    objectFit: "cover",
  },
}))

export default BrainSearchMessageExpertResponse

import { CommentMoreActionsDropdownFragment$key } from "@/comments/list/item/more-actions-dropdown/__generated__/CommentMoreActionsDropdownFragment.graphql"
import ContentSubscriptionDropdownItem from "@/content/subscription-button/ContentSubscriptionDropdownItem"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import DeleteCommentButton from "./delete-button/DeleteCommentButton"
import CommentMoreActionBookmarkButton from "@/comments/list/item/more-actions-dropdown/bookmark-button/CommentMoreActionBookmarkButton"

interface Props extends TestIDProps {
  commentKey: CommentMoreActionsDropdownFragment$key
  moreActionsButtonClassName?: string
}

function CommentMoreActionsDropdown(props: Props) {
  const {
    commentKey,
    moreActionsButtonClassName,
    testid = "CommentMoreActionsDropdown",
  } = props

  const comment = useFragment<CommentMoreActionsDropdownFragment$key>(
    graphql`
      fragment CommentMoreActionsDropdownFragment on Comment {
        id
        contentId
        parentCommentId
        productId
        ...usePermissionsFragment
      }
    `,
    commentKey
  )

  const permissions = usePermissions(comment)
  const canManage = permissions.has("comments.manage")

  // No action possible if a reply and you can't delete it
  if (comment.parentCommentId && !canManage) return null

  return (
    <DiscoMoreActionsDropdown
      moreActionsButtonClassName={moreActionsButtonClassName}
      testid={testid}
      // Don't mount the ContentSubscriptionDropdownItem till opened
      mountChildrenOnOpen
    >
      {/* Bookmark button */}
      <CommentMoreActionBookmarkButton
        contentId={comment.contentId}
        kind={"comment"}
        testid={`${testid}.toggle-bookmark`}
      />

      {/* Notifications for top-level comments */}
      {!comment.parentCommentId && (
        <ContentSubscriptionDropdownItem
          testid={`${testid}.toggle-subscription`}
          contentId={comment.contentId}
        />
      )}
      {/* Delete comment based on permission */}
      {canManage && (
        <DeleteCommentButton commentId={comment.id}>
          {(btnProps) => (
            <div {...btnProps}>
              <DiscoDropdownItem title={"Delete"} testid={`${testid}.delete`} />
            </div>
          )}
        </DeleteCommentButton>
      )}
    </DiscoMoreActionsDropdown>
  )
}

export default CommentMoreActionsDropdown

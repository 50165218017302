import useStartDirectMessage from "@/chat/hooks/useStartDirectMessage"
import { SidebarContactAdminsCarouselItemQuery } from "@/chat/sidebar/contact-admins/__generated__/SidebarContactAdminsCarouselItemQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoText } from "@disco-ui"
import { DiscoCardSkeleton } from "@disco-ui/card/DiscoCard"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useLazyLoadQuery } from "react-relay"

interface Props extends TestIDProps {
  userId: GlobalID
}

function SidebarContactAdminsCarouselItem({
  userId,
  testid = "SidebarContactAdminsCarouselItem",
}: Props) {
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()!
  const [startDm, isStartingDm] = useStartDirectMessage()

  const { user } = useLazyLoadQuery<SidebarContactAdminsCarouselItemQuery>(
    graphql`
      query SidebarContactAdminsCarouselItemQuery($userId: ID!, $organizationId: ID!) {
        user: node(id: $userId) {
          ... on User {
            id
            fullName
            ...ProfileAvatarFragment
            organizationMembership(organizationId: $organizationId) {
              id
            }
          }
        }
      }
    `,
    { userId, organizationId: activeOrganization.id }
  )
  if (!user) return null

  return (
    <div className={classes.card} data-testid={testid}>
      <ProfileAvatar testid={`${testid}.ProfileAvatar`} userKey={user} size={48} />
      <DiscoText
        variant={"body-sm-500"}
        testid={`${testid}.name`}
        marginTop={1.5}
        marginBottom={2}
        truncateText={2}
        title={user.fullName}
        className={"fs-mask"}
        align={"center"}
      >
        {user.fullName}
      </DiscoText>
      <DiscoButton
        testid={`${testid}.message-button`}
        onClick={handleClick}
        leftIcon={"message"}
        variant={"outlined"}
        color={"grey"}
        size={"small"}
        className={classes.messageButton}
        disabled={isStartingDm}
        shouldDisplaySpinner={isStartingDm}
      >
        <DiscoText variant={"body-sm-500"}>{"Message"}</DiscoText>
      </DiscoButton>
    </div>
  )

  function handleClick() {
    if (!user?.organizationMembership) return
    startDm([user.organizationMembership.id])
  }
}

const useStyles = makeUseStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: theme.palette.constants.borderSmall,
    padding: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
  messageButton: {
    padding: theme.spacing(1),
  },
}))

export default Relay.withSkeleton({
  component: SidebarContactAdminsCarouselItem,
  skeleton: DiscoCardSkeleton,
})

import ChannelsSidebar from "@/chat/sidebar/channels/ChannelsSidebar"
import ChatSidebarHeader from "@/chat/sidebar/ChatSidebarHeader"
import DirectMessagesSidebar from "@/chat/sidebar/DirectMessagesSidebar"
import ThreadsSidebar from "@/chat/sidebar/threads/ThreadsSidebar"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useStreamChat } from "@/core/context/StreamChatContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { DiscoSideBarSkeleton } from "@/organization/common/sidebar/CommunitySideBar"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useUnreadThreadCount from "@components/chat/channel/util/useUnreadThreadCount"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { DiscoSideBar, DiscoSideBarProps } from "@disco-ui"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { Route, Switch } from "react-router-dom"

function ChatSidebar(props: Partial<DiscoSideBarProps>) {
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()!
  const { unreadThreadCount } = useUnreadThreadCount()
  const { directMessages, communityChannels, productMemberChannels } = useStreamChat()
  const allChannels = communityChannels.concat(productMemberChannels)

  return (
    <DiscoSideBar
      {...props}
      fullHeight
      data-testid={"ChatSidebar"}
      header={<ChatSidebarHeader />}
      menuListProps={{
        ...props.menuListProps,
        className: classes.menuList,
      }}
      // Mount even when TemporarySideBar (on mobile) is closed so the first DM/thread can
      // be auto-loaded from the list
      ModalProps={{ keepMounted: true }}
      items={
        <div className={classes.container}>
          <div className={classes.tabsContainer}>
            <DiscoTabs
              testid={"ChatSidebar.tabs"}
              tabVariant={"grey-track"}
              fullWidth
              countBadgeProps={{ size: 14, fontSize: 10 }}
              size={"small"}
              routes={[
                ...ArrayUtils.spreadIf(
                  {
                    testid: "direct-messages",
                    label: "DMs",
                    pathname: ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL_ROOT,
                    notificationConfig: {
                      streamChannelIds: directMessages.map((c) => c.externalChannelId),
                    },
                  },
                  activeOrganization.isDmEnabled
                ),
                ...ArrayUtils.spreadIf(
                  {
                    testid: "channels",
                    label: "Channels",
                    pathname: ROUTE_NAMES.CHAT.CHANNEL.ROOT,
                    notificationConfig: {
                      streamChannelIds: allChannels.map((c) => c.externalChannelId),
                    },
                  },
                  activeOrganization.isChannelsEnabled
                ),
                {
                  testid: "threads",
                  label: "Threads",
                  pathname: ROUTE_NAMES.COMMUNITY.THREADS.DETAIL_ROOT,
                  notifications: unreadThreadCount,
                },
              ]}
            />
          </div>
          {/* Put the scroll container inside routes so scroll position resets when changing tabs */}
          <Switch>
            <Route
              key={`routes:=${ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL_ROOT}`}
              path={ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL_ROOT}
            >
              <ScrollShadowContainer hideScrollbar>
                <DirectMessagesSidebar />
              </ScrollShadowContainer>
            </Route>
            <Route
              key={`routes:=${ROUTE_NAMES.COMMUNITY.THREADS.DETAIL_ROOT}`}
              path={ROUTE_NAMES.COMMUNITY.THREADS.DETAIL_ROOT}
            >
              <ScrollShadowContainer hideScrollbar>
                <ThreadsSidebar />
              </ScrollShadowContainer>
            </Route>
            <Route
              key={`routes:=${ROUTE_NAMES.CHAT.CHANNEL.ROOT}`}
              path={ROUTE_NAMES.CHAT.CHANNEL.ROOT}
            >
              <ScrollShadowContainer hideScrollbar>
                <ChannelsSidebar />
              </ScrollShadowContainer>
            </Route>
          </Switch>
        </div>
      }
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  menuList: {
    height: "100%",
    padding: 0,
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tabsContainer: {
    padding: theme.spacing(2, 1.5, 1),
  },
}))

function ChatSidebarSkeleton() {
  return <DiscoSideBarSkeleton title={"Chat"} icon={"messages"} />
}

export default Relay.withSkeleton({
  component: ChatSidebar,
  skeleton: ChatSidebarSkeleton,
})

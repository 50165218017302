import { BrainSearchMessage } from "@/brain-search/internal/BrainSearchPage"
import CommunityBadge from "@/community/CommunityBadge"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import CertifiedBadge from "@/core/ui/iconsax/bold/verify.svg"
import { NodeFromConnection } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText, DiscoTextSkeleton } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { lighten } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"

interface Props extends TestIDProps {
  source: NodeFromConnection<BrainSearchMessage["sources"]>
}

function BrainSearchMessageInternalSource({ source }: Props) {
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()
  const history = useHistory()

  return (
    <DiscoContainerButton
      key={source.id}
      className={classNames(classes.source, classes.fadeIn)}
      onClick={handleClickSource}
    >
      <div className={classes.topSource}>
        <DiscoText variant={"body-xs-700"} color={"text.primary"} truncateText={3}>
          {getTitle()}
        </DiscoText>

        <CertifiedBadge className={classes.certifiedBadge} width={16} height={16} />
      </div>
      <div className={classes.bottomSource}>
        <div className={classes.expert}>
          {activeOrganization && (
            <CommunityBadge
              organizationKey={activeOrganization}
              size={24}
              classes={{ badge: classes.badge }}
            />
          )}
          <DiscoText variant={"body-xs"} color={"text.secondary"} truncateText={1}>
            {activeOrganization?.name}
          </DiscoText>
        </div>
      </div>
    </DiscoContainerButton>
  )

  function getTitle() {
    switch (source.entity) {
      case "content":
        if (!source.content) return "Content"
        return source.content.name
      case "asset":
        if (!source.asset) return "Asset"
        return source.asset.name
      case "training_data":
        if (!source.trainingData) return "Training Data"
        return source.trainingData.name
      default:
        return "Source"
    }
  }

  function handleClickSource() {
    switch (source.entity) {
      case "content":
        if (!source.content) return
        return history.push({
          pathname: location.pathname,
          search: setSearchParams(location.search, { contentId: source.entityId }),
        })
      case "asset":
        if (!source.asset) return
        return history.push({
          pathname: location.pathname,
          search: setSearchParams<GlobalDrawerParams<"asset">>(location.search, {
            assetId: source.entityId,
          }),
        })
      case "training_data":
        if (!source.trainingData) return
        return window.open(source.trainingData.url, "_blank")
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  source: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.groovy.neutral[100],
    border: theme.palette.constants.borderSmall,
    minHeight: "80px",

    "&:hover": {
      borderColor: "#4D47DC",
      backgroundColor: lighten("#4D47DC", 0.9),
      boxShadow:
        "0px 2px 4px 0px rgba(63, 63, 70, 0.04), 0px 6px 18px 0px rgba(63, 63, 70, 0.08)",
    },
  },
  topSource: {
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    display: "flex",
    flex: 1,
    gap: theme.spacing(1),
  },
  certifiedBadge: {
    color: theme.palette.primary.main,
    right: 0,
    flexShrink: 0,
  },
  bottomSource: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  expert: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1),
    width: "100%",
  },
  badge: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
  },
  fadeIn: {
    animation: "$fadeIn 1s",
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}))

export function BrainSearchMessageSourceSkeleton() {
  const classes = useStyles()

  return (
    <DiscoContainerButton className={classes.source}>
      <div className={classes.topSource}>
        <DiscoTextSkeleton width={"100%"} />
      </div>
      <div className={classes.bottomSource}>
        <div className={classes.expert}>
          <div className={classes.badge} />
          <DiscoTextSkeleton width={"50%"} />
        </div>
      </div>
    </DiscoContainerButton>
  )
}

export default observer(BrainSearchMessageInternalSource)

import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const CertificatesDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "certificates-drawer-content" */ "@/certificates/drawer/CertificatesDrawerContent"
    )
)

export default function CertificatesDrawer() {
  const drawer = useGlobalDrawer("certificates")
  const classes = useStyles()
  const unsavedChangesModal = useInitUnsavedChangesModalContext()

  return (
    <DiscoDrawer
      open={drawer.isOpen}
      size={"xl"}
      fullScreen
      onClose={drawer.close}
      // only want to change background color of drawer on list page
      containerClasses={
        !drawer.params.certificateDrawerTab && !drawer.params.certificateId
          ? { drawerContainer: classes.drawerList }
          : { drawerContainer: classes.drawer }
      }
    >
      <UnsavedChangesModalProvider {...unsavedChangesModal}>
        {drawer.params.drawerCertificatesProductId && (
          <CertificatesDrawerContent
            productId={drawer.params.drawerCertificatesProductId}
          />
        )}
      </UnsavedChangesModalProvider>
    </DiscoDrawer>
  )
}

const useStyles = makeUseStyles((theme) => ({
  drawerList: {
    padding: 0,
    backgroundColor: theme.palette.background.default,
  },
  drawer: {
    padding: 0,
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "block",
    },
  },
}))

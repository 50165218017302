import BookmarksSubtabs from "@/bookmarks/drawer/BookmarksSubtabs"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer, DiscoDrawerHeader, DiscoSpinner } from "@disco-ui"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"

const BookmarksDrawerContent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "bookmarks-drawer-content" */ "@/bookmarks/drawer/BookmarksDrawerContent"
    )
)

function BookmarksDrawer() {
  const drawer = useGlobalDrawer("bookmarks")
  const classes = useStyles()

  return (
    <DiscoDrawer
      open={drawer.isOpen}
      size={"medium"}
      onClose={drawer.close}
      containerClasses={{
        drawerContainer: classes.drawer,
      }}
    >
      <DiscoDrawerHeader
        className={classes.header}
        testid={"BookmarksDrawer"}
        title={"Bookmarks"}
        onClose={drawer.close}
        customSubTabs={<BookmarksSubtabs />}
        disableExpand
      />
      <BookmarksDrawerContent tab={drawer.params.bookmarksTab} />
    </DiscoDrawer>
  )
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    padding: theme.spacing(2, 3, 0, 3),
  },
  drawer: {
    padding: theme.spacing(1, 3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
}))

export default Relay.withSkeleton({
  component: BookmarksDrawer,
  skeleton: () => <DiscoSpinner />,
})

import { CommentsSectionContentPaginationQuery } from "@/comments/__generated__/CommentsSectionContentPaginationQuery.graphql"
import { CommentsSectionContentUsagePaginationQuery } from "@/comments/__generated__/CommentsSectionContentUsagePaginationQuery.graphql"
import { CommentsSection_ContentCommentsQuery } from "@/comments/__generated__/CommentsSection_ContentCommentsQuery.graphql"
import { CommentsSection_ContentPaginationFragment$key } from "@/comments/__generated__/CommentsSection_ContentPaginationFragment.graphql"
import { CommentsSection_ContentUsagePaginationFragment$key } from "@/comments/__generated__/CommentsSection_ContentUsagePaginationFragment.graphql"
import CommentsListItem, {
  CommentsListItemSkeleton,
} from "@/comments/list/item/CommentsListItem"
import CommentUtils from "@/comments/utils/CommentUtils"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { RequireOnlyOne } from "@/types/util/RequireOnlyOne"
import { TextVariantWithModifiers } from "@assets/style/appMuiTheme"
import styleIf from "@assets/style/util/styleIf"
import { DiscoButton, DiscoDropdown, DiscoText } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { Grid } from "@material-ui/core"
import { setSearchParams, useQueryParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import React, { useEffect, useRef } from "react"
import { useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { useHistory, useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"
import { CommentsQueryParams } from "./editor/CommentEditor"

const COMMENTS_PER_PAGE = 6

type CommentsSectionProps = {
  id?: string
  className?: string
  classes?: { editor?: string; commentsList?: string }
  headerVariant?: TextVariantWithModifiers
  collapsed?: boolean
  title?: string
  testid?: string
  hideTitle?: boolean
} & RequireOnlyOne<
  {
    contentId: GlobalID
    contentUsageId: GlobalID
  },
  "contentId" | "contentUsageId"
>
export type CommentsSectionParams = {
  scrollToComments?: "true"
}

function CommentsSection({
  id,
  contentId,
  contentUsageId,
  className,
  classes: propsClasses,
  collapsed = false,
  headerVariant = "body-md-600",
  title,
  hideTitle = false,
}: CommentsSectionProps) {
  const [params, setParams] = useQueryParamState<CommentsQueryParams>()

  const { content, contentUsage } =
    useLazyLoadQuery<CommentsSection_ContentCommentsQuery>(
      graphql`
        query CommentsSection_ContentCommentsQuery(
          $contentId: ID!
          $contentUsageId: ID!
          $first: Int!
          $after: String
          $contentOrderBy: ContentCommentsOrderByInput
          $contentUsageOrderBy: ContentUsageCommentsOrderByInput
        ) {
          content: node(id: $contentId) {
            ... on Content {
              id
              ...CommentUtils_useTotalCommentsCount
              ...CommentsSection_ContentPaginationFragment
                @arguments(first: $first, after: $after, orderBy: $contentOrderBy)
            }
          }
          contentUsage: node(id: $contentUsageId) {
            ... on ContentUsage {
              id
              contentId
              ...CommentUtils_useTotalCommentsOnUsageCount
              ...CommentsSection_ContentUsagePaginationFragment
                @arguments(first: $first, after: $after, orderBy: $contentUsageOrderBy)
            }
          }
        }
      `,
      {
        contentId: contentId || "",
        contentUsageId: contentUsageId || "",
        contentOrderBy: { direction: params.commentOrder || "DESC" },
        contentUsageOrderBy: { direction: params.commentOrder || "DESC" },
        first: COMMENTS_PER_PAGE,
      },
      {
        fetchPolicy: "store-and-network",
      }
    )

  const {
    data: contentPaginationData,
    loadNext: contentLoadNext,
    hasNext: contentHasNext,
    isLoadingNext: contentIsLoadingNext,
    refetch: contentRefetch,
  } = usePaginationFragment<
    CommentsSectionContentPaginationQuery,
    CommentsSection_ContentPaginationFragment$key
  >(
    graphql`
      fragment CommentsSection_ContentPaginationFragment on Content
      @refetchable(queryName: "CommentsSectionContentPaginationQuery")
      @argumentDefinitions(
        first: { type: "Int!" }
        after: { type: "String" }
        orderBy: { type: "ContentCommentsOrderByInput" }
      ) {
        comments(first: $first, after: $after, orderBy: $orderBy)
          @connection(key: "CommentsSection__comments") {
          totalCount
          edges {
            node {
              id
              replies {
                totalCount
              }
              ...CommentsListItem_comment
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    content
  )

  const {
    data: contentUsagePaginationData,
    loadNext: contentUsageLoadNext,
    hasNext: contentUsageHasNext,
    isLoadingNext: contentUsageIsLoadingNext,
    refetch: contentUsageRefetch,
  } = usePaginationFragment<
    CommentsSectionContentUsagePaginationQuery,
    CommentsSection_ContentUsagePaginationFragment$key
  >(
    graphql`
      fragment CommentsSection_ContentUsagePaginationFragment on ContentUsage
      @refetchable(queryName: "CommentsSectionContentUsagePaginationQuery")
      @argumentDefinitions(
        first: { type: "Int!" }
        after: { type: "String" }
        orderBy: { type: "ContentUsageCommentsOrderByInput" }
      ) {
        comments(first: $first, after: $after, orderBy: $orderBy)
          @connection(key: "CommentsSection__comments") {
          totalCount
          edges {
            node {
              id
              replies {
                totalCount
              }
              ...CommentsListItem_comment
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    contentUsage
  )

  useEffect(() => {
    if (!params.commentOrder) return
    const refetch = contentUsageId ? contentUsageRefetch : contentRefetch
    refetch({
      first: COMMENTS_PER_PAGE,
      orderBy: { direction: params.commentOrder },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.commentOrder])

  const classes = useStyles({ hideTitle })
  const { scrollToComments } = useQueryParams<CommentsSectionParams>()
  const shouldScrollToComments = scrollToComments === "true"
  const history = useHistory()
  const location = useLocation()
  const ref = useRef<HTMLDivElement>(null)
  const commentsSectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!shouldScrollToComments) return
    // Using setTimeout to delay scrolling so that all the comments load before scrolling to the first one
    const timer = setTimeout(() => {
      commentsSectionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })

      history.replace({
        ...location,
        search: setSearchParams(location.search, { scrollToComments: undefined }),
      })
    }, 100)

    // Cleanup function to clear the timer
    return () => clearTimeout(timer)
  }, [shouldScrollToComments, history, location])

  const comments = Relay.connectionToArray(
    contentPaginationData?.comments || contentUsagePaginationData?.comments
  )
  const totalCount = CommentUtils.useTotalCommentsCount(content, contentUsage)

  return (
    <div ref={commentsSectionRef} id={id} className={className}>
      {/* Header */}
      <div className={classes.commentHeader}>
        {!hideTitle && (
          <DiscoText
            data-testid={`CommentsSection.comments-count`}
            variant={headerVariant}
            marginBottom={1}
          >
            {title || `Comments (${totalCount})`}
          </DiscoText>
        )}
        <DiscoDropdown
          menuButton={({ onClick }) => (
            <DiscoButton
              size={"small"}
              color={"grey"}
              variant={"outlined"}
              onClick={onClick}
              rightIcon={"chevron-down"}
              testid={"CommentsSection.sort-button"}
            >
              {params.commentOrder === "ASC" ? "Oldest" : "Newest"}
            </DiscoButton>
          )}
        >
          <DiscoDropdownItem
            title={"Newest"}
            onClick={() => setParams({ commentOrder: "DESC" })}
            testid={"CommentsSection.sort-button.newest"}
          />
          <DiscoDropdownItem
            title={"Oldest"}
            onClick={() => setParams({ commentOrder: "ASC" })}
            testid={"CommentsSection.sort-button.oldest"}
          />
        </DiscoDropdown>
      </div>

      {/* List of Comments */}
      {comments.length > 0 && (
        <Grid
          ref={ref}
          container
          classes={{
            root: classNames(classes.commentsContainer, propsClasses?.commentsList),
          }}
        >
          {comments.map((comment, i) => (
            <Grid key={comment.id} data-testid={`CommentsList.item-${i}`} item xs={12}>
              <CommentsListItem
                testid={"CommentsListItem"}
                commentKey={comment}
                classes={propsClasses}
                collapsed={collapsed}
              />
            </Grid>
          ))}
          {(contentHasNext || contentUsageHasNext) && !collapsed && (
            <DiscoScrolledIntoView
              onScrolledIntoView={() => {
                if (contentUsage) contentUsageLoadNext(COMMENTS_PER_PAGE)
                else contentLoadNext(COMMENTS_PER_PAGE)
              }}
              isLoading={contentUsageIsLoadingNext || contentIsLoadingNext}
              skeleton={
                <>
                  <Grid xs={12} item>
                    <CommentsListItemSkeleton />
                  </Grid>
                  <Grid xs={12} item>
                    <CommentsListItemSkeleton />
                  </Grid>
                  <Grid xs={12} item>
                    <CommentsListItemSkeleton />
                  </Grid>
                </>
              }
            />
          )}
        </Grid>
      )}
    </div>
  )
}

export const CommentsSectionSkeleton: React.FC<{
  className?: string
  headerVariant?: TextVariantWithModifiers
  hideTitle?: boolean
}> = (props) => {
  const { className, headerVariant = "body-lg-600", hideTitle = false } = props
  return (
    <div className={className}>
      {/* Header */}
      {!hideTitle && <DiscoText variant={headerVariant}>{"Comments"}</DiscoText>}

      {/* List of Comments */}
      <CommentsListItemSkeleton />
      <CommentsListItemSkeleton />
      <CommentsListItemSkeleton />
    </div>
  )
}

type StyleProps = {
  hideTitle: boolean
}

const useStyles = makeUseStyles((theme) => ({
  commentsContainer: {
    position: "relative",
    zIndex: 2,
    gap: theme.spacing(2),
  },
  commentHeader: ({ hideTitle }: StyleProps) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(1),

    ...styleIf(hideTitle, {
      justifyContent: "flex-end",
    }),
  }),
}))

export default Relay.withSkeleton<CommentsSectionProps>({
  component: CommentsSection,
  skeleton: CommentsSectionSkeleton,
})

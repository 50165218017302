import useUserTimezone from "@/user/util/useUserTimezone"
import { DiscoText } from "@disco-ui"
import useInterval from "@utils/hook/useInterval"
import { getTimeDifferenceAsText } from "@utils/time/timeUtils"
import { utcToZonedTime } from "date-fns-tz"
import { useState } from "react"

type CommentTimestampProps = {
  creationDatetime: string
}
function CommentTimestamp({ creationDatetime }: CommentTimestampProps) {
  const timeZone = useUserTimezone()
  const [timestamp, setTimestamp] = useState(getTimestamp())

  useInterval(() => {
    setTimestamp(getTimestamp())
  }, 1000)
  return (
    <div>
      <DiscoText
        testid={`CommentTimestamp`}
        color={"groovy.onDark.300"}
        variant={"body-xs-500"}
      >
        {timestamp}
      </DiscoText>
    </div>
  )

  function getTimestamp() {
    return getTimeDifferenceAsText(utcToZonedTime(new Date(creationDatetime), timeZone))
  }
}

export default CommentTimestamp

import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import NewDirectMessageButton from "@components/chat/new-direct-message/NewDirectMessageButton"
import { DiscoDropdown, DiscoIcon, DiscoIconButton } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoSidebarHeader from "@disco-ui/sidebar/DiscoSidebarHeader"

export default function ChatSidebarHeader() {
  const activeOrganization = useActiveOrganization()!

  return (
    <DiscoSidebarHeader
      title={"Chat"}
      icon={"messages"}
      actions={
        activeOrganization.isDmEnabled && (
          <DiscoDropdown
            menuButton={({ onClick }) => (
              <DiscoIconButton
                size={"small"}
                onClick={onClick}
                testid={"ChatSidebarHeader.add-button"}
              >
                <DiscoIcon icon={"add"} />
              </DiscoIconButton>
            )}
            horizontal={"right"}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <NewDirectMessageButton stopPropagation={false}>
              {({ onClick }) => (
                <DiscoDropdownItem
                  testid={"ChatSidebarheader.direct-message"}
                  onClick={onClick}
                  title={"New Direct Message"}
                />
              )}
            </NewDirectMessageButton>
          </DiscoDropdown>
        )
      }
    />
  )
}

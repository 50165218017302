import Badge from "@/admin/experiences/badges/Badge"
import AppSidebarItem from "@/apps/sidebar-item/AppSidebarItem"
import { ChannelsSidebarProductFragment$key } from "@/chat/sidebar/channels/__generated__/ChannelsSidebarProductFragment.graphql"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CommunitySidebarItem from "@/organization/common/sidebar/CommunitySidebarItem"
import ProductStatusChip from "@/product/common/status-chip/ProductStatusChip"
import ChatChannelSideBarItem from "@/product/sidebar/chat/channels/ChatChannelSideBarItem"
import Relay from "@/relay/relayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

type ChannelsSidebarProductProps = TestIDProps & {
  productKey: ChannelsSidebarProductFragment$key
}

export default function ChannelsSidebarProduct(props: ChannelsSidebarProductProps) {
  const { productKey, testid = "ChannelsSidebarProduct" } = props

  const product = useFragment<ChannelsSidebarProductFragment$key>(
    graphql`
      fragment ChannelsSidebarProductFragment on Product {
        id
        name
        slug
        status
        badge {
          id
          ...BadgeFragment
        }
        productApps(kinds: [chat_channel, nav_folder]) {
          edges {
            node {
              id
              kind
              customAppTitle
              productApps(kinds: [chat_channel]) {
                edges {
                  node {
                    id
                    chatChannel {
                      externalChannelId
                    }
                    ...ChatChannelSideBarItemFragment
                  }
                }
              }
              ...ChatChannelSideBarItemFragment
              ...AppSidebarItemFragment
            }
          }
        }
      }
    `,
    productKey
  )

  const classes = useStyles()
  const apps = Relay.connectionToArray(product.productApps)
    // Don't show folders without any channels in them
    .filter((a) => a.kind === "chat_channel" || a.productApps?.edges.length)

  return (
    <CommunitySidebarItem
      testid={testid}
      menuItemClasses={{ root: classes.menuItem }}
      name={product.name}
      leftIcon={
        product.badge ? (
          <Badge badgeKey={product.badge} size={24} />
        ) : (
          <div className={classes.emptyBadgePlaceholder} />
        )
      }
      showItemsRoute={ROUTE_NAMES.CHAT.ROOT}
      rightContent={
        product.status === "draft" && (
          <ProductStatusChip status={product.status} testid={testid} />
        )
      }
      indentNestedItems
    >
      {apps.map((app) => {
        if (app.kind === "nav_folder") {
          const folderApps = Relay.connectionToArray(app.productApps)
          const folderTestid = `${testid}.${app.customAppTitle}`
          return (
            <AppSidebarItem
              key={app.id}
              appKey={app}
              testid={folderTestid}
              notificationConfig={{
                streamChannelIds: folderApps.map((a) => a.chatChannel!.externalChannelId),
              }}
              name={app.customAppTitle || ""}
              collapsible
              indentNestedItems
              hideLeftIcon
            >
              {folderApps.map((folderApp) => (
                <ChatChannelSideBarItem
                  key={folderApp.id}
                  testid={folderTestid}
                  appKey={folderApp}
                  isChannelsSidebar
                />
              ))}
            </AppSidebarItem>
          )
        }
        return (
          <ChatChannelSideBarItem
            key={app.id}
            testid={testid}
            appKey={app}
            isChannelsSidebar
          />
        )
      })}
    </CommunitySidebarItem>
  )
}

const useStyles = makeUseStyles((theme) => ({
  menuItem: {
    cursor: "default",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  emptyBadgePlaceholder: {
    height: "24px",
    width: "24px",
    borderRadius: theme.measure.borderRadius.default,
    backgroundColor: theme.palette.background.paper,
  },
}))

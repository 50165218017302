import useDismissNotifications from "@/apps/util/hooks/useDismissNotifications"
import { useCommentsSectionContext } from "@/comments/CommentsSectionContext"
import CommentTimestamp from "@/comments/list/item/comment-timestamp/CommentTimestamp"
import CommentMoreActionsDropdown from "@/comments/list/item/more-actions-dropdown/CommentMoreActionsDropdown"
import { CommentsListReplyItem_comment$key } from "@/comments/list/item/reply-item/__generated__/CommentsListReplyItem_comment.graphql"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ContentReactionButton from "@/product/common/content/button/ContentReactionButton"
import { GlobalID } from "@/relay/RelayTypes"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import styleIf from "@assets/style/util/styleIf"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import ContentUniqueReactionsList from "@components/reactions/ContentUniqueReactionsList"
import { DiscoText, DiscoTextButton } from "@disco-ui"
import Grid from "@material-ui/core/Grid"
import { useTheme } from "@material-ui/core/styles"
import { useQueryParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import { useEffect, useRef } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface CommentsListReplyItemProps {
  commentKey: CommentsListReplyItem_comment$key
  testid: string
  onClickReply: (toUser: { id: GlobalID; fullName: string }) => void
}

type CommentsListReplyItemParam = {
  // The anchor comment ID from the query param
  comment?: GlobalID
}

function CommentsListReplyItem({
  commentKey,
  testid,
  onClickReply,
}: CommentsListReplyItemProps) {
  const theme = useTheme()
  const labels = useLabels()
  const showOnHoverClasses = useShowOnHoverStyles()
  const commentRef = useRef<HTMLDivElement>(null)
  const { comment: anchorCommentId } = useQueryParams<CommentsListReplyItemParam>()
  const { replyTo } = useCommentsSectionContext()
  const activeProduct = useActiveProduct()
  const isNonMember = Boolean(activeProduct && !activeProduct.viewerMembership)

  const comment = useFragment<CommentsListReplyItem_comment$key>(
    graphql`
      fragment CommentsListReplyItem_comment on Comment {
        id
        productId
        contentId
        creationDatetime
        ...CommentMoreActionsDropdownFragment
        user {
          id
          fullName
          ...ProfileAvatarWithDetailsFragment
        }
        content {
          richEditorDescriptionContent
          ...DiscoEditorMentionsFragment
          ...ContentReactionButton_ContentFragment
        }
      }
    `,
    commentKey
  )

  const isCurrentComment = comment.id === anchorCommentId
  const isReplying = comment.user === replyTo
  const classes = useStyles({ isReplying })

  useEffect(() => {
    // Scroll to the current comment and focus it
    if (isCurrentComment && commentRef.current) {
      commentRef.current.focus()
      window.scrollTo({ top: commentRef.current.offsetTop, behavior: "smooth" })
    }
  }, [isCurrentComment, commentRef])

  // Dimiss any notification related to this comment
  useDismissNotifications({
    entityId: comment.id,
    kind: "content-comment-published",
  })
  if (!comment) return null

  return (
    <div
      ref={commentRef}
      className={classNames(classes.commentContainer, showOnHoverClasses.hoverable)}
      data-testid={testid}
      id={comment.id}
      role={"button"}
      // Make this div focusable
      /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
      tabIndex={-1}
    >
      {/* More Actions Dropdown */}
      <CommentMoreActionsDropdown
        moreActionsButtonClassName={classNames(
          classes.commentMoreActionsButton,
          showOnHoverClasses.showable
        )}
        commentKey={comment}
      />

      {/* Commentor Avatar and Name */}
      <div>
        <ProfileAvatarWithDetails
          avatarSize={32}
          userKey={comment.user}
          testid={"name-and-avatar"}
          horizontal={true}
          details={<CommentTimestamp creationDatetime={comment.creationDatetime} />}
          highlight={isReplying ? "full" : "none"}
        />
      </div>

      <div className={classes.commentsContentContainer}>
        {/* Comment Text */}
        <div className={classes.commentTextContainer}>{renderCommentText()}</div>

        {/* Comment Reactions */}
        {comment.content && (
          <div className={classes.reactionsRow}>
            <ContentReactionButton
              testid={"CommentReply"}
              contentId={comment.contentId}
              contentKey={comment?.content}
              hideNumber
              hideText
              size={"xsmall"}
            />
            <ContentUniqueReactionsList contentId={comment.contentId} />
          </div>
        )}
        <Grid container alignItems={"center"}>
          {/* Reply button for members that can comment */}
          {!isNonMember && comment.user && (
            <DiscoTextButton
              onClick={() => onClickReply(comment.user!)}
              testid={`${testid}.reply-button`}
              className={classes.replyButton}
              textVariant={"body-sm-600"}
            >
              {"Reply"}
            </DiscoTextButton>
          )}
        </Grid>
      </div>
    </div>
  )

  function renderCommentText() {
    if (comment.content?.richEditorDescriptionContent) {
      return (
        <DiscoEditor
          testid={`${testid}.rich-editor`}
          className={classes.commentText}
          defaultValue={comment.content.richEditorDescriptionContent}
          textColor={theme.palette.text.secondary}
          backgroundColor={"transparent"}
          readOnly
          disableGutter
          config={"comment"}
          mentionsContentKey={comment.content}
        />
      )
    }
    return (
      <DiscoText
        testid={"NoLongerPartOfSpace"}
        className={classes.userNoLongerPartOfSpaceText}
        variant={"body-md"}
        color={"text.secondary"}
      >{`${
        comment.productId
          ? labels.product_member.singular
          : labels.organization_member.singular
      } no longer part of this ${
        comment.productId ? labels.experience.singular : "Community"
      }`}</DiscoText>
    )
  }
}

type StyleProps = {
  isReplying: boolean
}

const useStyles = makeUseStyles((theme) => ({
  commentContainer: {
    position: "relative",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    marginLeft: theme.spacing(3),
    borderLeft: `2px solid ${theme.palette.groovy.neutral[300]}`,
  },
  commentMoreActionsButton: {
    position: "absolute",
    top: `${theme.spacing(1)}px`,
    right: "0",
  },
  commentsContentContainer: {
    paddingLeft: theme.spacing(5.5),
    marginTop: theme.spacing(-1),
  },
  commentTextContainer: ({ isReplying }: StyleProps) => ({
    paddingBottom: theme.spacing(0.25),
    ...styleIf(isReplying, {
      marginRight: theme.spacing(-0.5),
      marginLeft: theme.spacing(-6.5),
      paddingLeft: theme.spacing(6.5),
      backgroundColor: theme.palette.groovy.blue[100],
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    }),
  }),
  commentText: {
    overflowWrap: "inherit",
  },
  replyButton: {
    paddingTop: theme.spacing(1),
    justifyContent: "left",
  },
  userNoLongerPartOfSpaceText: {
    fontStyle: "italic !important",
  },
  reactionsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
}))

export default CommentsListReplyItem

import ROUTE_NAMES from "@/core/route/util/routeNames"
import { DiscoIcon, DiscoIconButton } from "@disco-ui"
import DiscoSidebarHeader from "@disco-ui/sidebar/DiscoSidebarHeader"
import { generatePath, useHistory } from "react-router-dom"

export default function BrainSearchSidebarHeader() {
  const history = useHistory()

  return (
    <DiscoSidebarHeader
      title={"Ask AI"}
      icon={"stars"}
      actions={
        <DiscoIconButton
          onClick={handleClick}
          width={24}
          height={24}
          tooltip={"New AI Conversation"}
        >
          <DiscoIcon icon={"add"} width={24} height={24} />
        </DiscoIconButton>
      }
    />
  )

  function handleClick() {
    history.push(generatePath(ROUTE_NAMES.BRAIN.ROOT))
  }
}

import BrainSearchMessageSuggestedFollowup from "@/brain-search/internal/BrainSearchMessageSuggestedFollowup"
import { BrainSearchMessage } from "@/brain-search/internal/BrainSearchPage"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"

interface Props extends TestIDProps {
  message: BrainSearchMessage
  createMessage: (text: string) => Promise<void>
}

function BrainSearchMessageSuggestedFollowupList({ message, createMessage }: Props) {
  const classes = useStyles()
  const theme = useTheme()

  const suggestedFollowups = Relay.connectionToArray(message.suggestedFollowups)

  if (isEmpty()) return null

  return (
    <div>
      <div className={classes.responseTag}>
        <DiscoIcon
          icon={"question"}
          width={16}
          height={16}
          color={theme.palette.text.primary}
          active
        />
        <DiscoText variant={"heading-xs-700"}>{"Related Questions"}</DiscoText>
      </div>

      <div className={classes.suggestedFollowupsList}>
        {suggestedFollowups.map((suggestedFollowup) => (
          <BrainSearchMessageSuggestedFollowup
            key={suggestedFollowup.text}
            suggestedFollowup={suggestedFollowup}
            onClickSuggestion={createMessage}
          />
        ))}
      </div>
    </div>
  )

  function isEmpty() {
    if (!suggestedFollowups?.length) return true
    if (!suggestedFollowups.filter((sf) => Boolean(sf.text.length)).length) return true
    return false
  }
}

const useStyles = makeUseStyles((theme) => ({
  suggestedFollowupsList: {
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderSmall,
    boxShadow: theme.palette.groovyDepths.xs,
    padding: theme.spacing(1),
  },
  responseTag: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))

export default BrainSearchMessageSuggestedFollowupList
